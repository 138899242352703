import { Link } from "react-router-dom";

export const NavigationChannel = (props) => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light  bg-light bg-gradient shadow-sm">
      <div className="container-fluid">
        
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            
            <li>
              <Link className="nav-link" to={`/channels/${props.id}/reports`}>Informes</Link>
            </li>
            <li>
              <Link className="nav-link" to={`/channels/${props.id}/category`}>Categorias</Link>
            </li>
            <li>
              <Link className="nav-link" to={`/channels/${props.id}/mapping`}>Variables</Link>
            </li>
            <li>
              <Link className="nav-link" to={`/channels/${props.id}/exclusions`}>Exclusiones</Link>
            </li>
            <li>
              <Link className="nav-link" to={`/channels/${props.id}/preview`}>Vista Previa</Link>
            </li>
            <li>
              <Link className="nav-link" to={`/channels/${props.id}/settings`}>Ajustes</Link>
            </li>
          
            
          </ul>
          
        </div>
      </div>
    </nav>
  )
}


