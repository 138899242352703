import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import channelService from "../../../services/channels";

export const Oauth = (props) => {

    const [message, setMessage] = useState("");
    const [settings, setSettings] = useState("");
    /*
    const [categories, setCategories] = useState("");
    const [searchingCategory, setSearchingCategory] = useState(false);
    const [searchingType, setSearchingType ] = useState(false);
    
    */
    
    useEffect(() => {
        
        channelService.getChannelSettings(props.id).then(
        (response) => {
            setSettings(response.data.data);
            setMessage(false);
        },
        (error) => {
            setMessage("error al cargar la configuración del canal: " + error);
            
        }
        );
    }, []);

    

   /*
    
    const handleLoggin = () => {

        //window.location.href = "https://google.com";
        window.open("https://google.com", "_blank")
        
    }*/

    

    return (
        
        <div className="row">
            {message && (
                <div className="form-group">
                <div className="alert alert-danger" role="alert">
                    {message}
                </div>
                </div>
            )}
            <p>Inicie sesión en el Canal para enlazar con la aplicación</p>
            { settings && settings.oauthURL !== undefined ? 
            <Link to= {{ pathname: settings.oauthURL + "?state=" + settings.state }} target="_blank">
            <button 
                className="btn btn-primary btn-lg btn-block" 
                type="button" 
                
                >Iniciar Sesión
            </button>
            </Link>
            : null}
        </div>
        
        
    );
  }
