import React, { useState } from "react";
import channelService from "../../services/channels";

export const SearchCategories = (props) => {
    
    const [channelCategories, setChannelCategories] = useState("");
    const [keyword, setKeyword] = useState('');
    const [message, setMessage] = useState('');
    

    const handleKeyword = (categoryId, category, platform) => {
        let categoryId2;

        if(platform === "amazon")
        {
            categoryId2 = categoryId;
            categoryId = ""; //Temporal Testing
        }
        else{
            //Todo
        }
        props.matchKeyword({categoryId,categoryId2,category});
        
    }

    const handleChange = (e) => {
       setKeyword(e.target.value);
       //Establecemos un mínimo de catácteres para lanzar la búsqueda
       if(keyword.length > 3){
            filterCategory(keyword);
        }
    }

    const filterCategory = (keyword) => {
        
        console.log(props.channel);
        channelService.postSearchCategories(props.channel,keyword).then(
            (response) => {
                //console.log(response.data.data);
                setChannelCategories(response.data.data);
                setMessage(false);
            },
            (error) => {
                setMessage("error al cargar las categorías: " + error);
                
            }
        );

        
    }

    return(
        <div className="col searchingBox" key={"searchingBox"}>
            <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon1">Searching...</span>
            </div>
            <input  
                type="text" 
                name="category" 
                className="form-control" 
                placeholder="Search Category" 
                value={keyword}
                onChange={handleChange}
            />
            <ul className="list-group">
                { channelCategories ? channelCategories.map((f)=>
                            <li 
                            key={f.category_id}
                            className="list-group-item searching-li"
                            onClick={() => handleKeyword(f.categoryid, f.path, f.platform)}>{f.path}</li>
                            )
                            :
                            'loading' }
                
            </ul>
        </div>

    )}