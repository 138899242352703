import React from "react";
import { Link } from "react-router-dom"
import { useState, useEffect } from "react";
import channelService from "../../services/channels";
import { RulePlace } from "../placeholders/rule";


export const Exclusions = (props) => {

    const [exclusionsData, setExclusionsData] = useState("");
    const [message, setMessage] = useState("");
    
    useEffect(() => {
        
        channelService.getChannelExclusions(props.id,).then(
        (response) => {

            setExclusionsData(response.data.data);
            setMessage(false);
        },
        (error) => {
            setMessage("error al cargar los Campos: " + error);
        }
        );
        //actualiza el componente cada vez que cambia exclusionData
    }, []);

    const handleAddExclusion = () => {
        
        channelService.addExclusion(props.id).then(
            (response) => {
                setExclusionsData([...exclusionsData, response.data.data]);
                setMessage(false);
            },
            (error) => {
                setMessage("No se pudo crear la Regla de Exclusión: " + error);
                
            }
        );
    }

    
    const handleDeleteExclusion = (exclusion) => {

        channelService.deleteExclusion(exclusion).then(
            (response) => {
                //eliminamos del array de Exclusiones el id eliminado TEMPORAL lo ideal seria que volvieran los datos en la respuesta.
                const resultDelete = exclusionsData.filter(exc => exc._id !== exclusion);

                setExclusionsData(resultDelete);
                setMessage(false);
            },
            (error) => {
                setMessage("No se pudo eliminar la Regla de Exclusión: " + error);
                
            }
        );
    }

    
    
     
    return (
        
        <div>
            
            <h4>Reglas de Exclusión:</h4>

            { message && (
            <div className="form-group">
              <div className="alert alert-danger" role="alert">
                {message}
              </div>
            </div>
            )}
            

            <div>

                
                <button 
                            type="button" 
                            className="btn btn-primary btn-cardRule"
                            onClick={() => handleAddExclusion()}
                >Añadir Regla de Exclusión</button>

                {exclusionsData ? exclusionsData
                    .map((exclusion) => (

                    

                    <div className="card mb-3" key={exclusion._id} id={exclusion._id}>
                        
                        <button
                            type="button" 
                            className="btn-close"
                            onClick={e =>
                            window.confirm("Seguro que quieres Eliminar esta regla de Exclusión") &&
                            handleDeleteExclusion(exclusion._id)
                            }
                        ></button>  
                        <Link className="nav-link" to={`/channels/${props.id}/exclusions/${exclusion._id}`}>
                            <div className="card-body">
                            <h5 className="card-title">{exclusion.name}</h5>
                            <h6 className="card-subtitle mb-2 text-muted">{exclusion.updatedAt}</h6>
                            <p className="card-text"></p>
                            </div>
                        </Link>
                    
                       
                    </div>
                    
                    
                
                )
                ) : <RulePlace repeat= {1}/>
                }
            </div>
            
            
        </div>
        
        
    );
  }
