import React, { useState, useEffect } from "react";
import channelService from "../../services/channels";
import { CategorydPlace } from "../placeholders/category";
import { SearchCategories } from "./searchCategories";
import { SearchType } from "./searchType";

export const Categories = (props) => {

    const [categories, setCategories] = useState("");
    const [searchingCategory, setSearchingCategory] = useState(false);
    const [searchingType, setSearchingType ] = useState(false);
    const [message, setMessage] = useState("");
    
    
    useEffect(() => {
        
        channelService.getCategoryMap(props.id).then(
        (response) => {
            setCategories(response.data.data);
            setMessage(false);
        },
        (error) => {
            setMessage("error al cargar las categorías: " + error);
            
        }
        );
    }, []);

    

    const handleSearch = (index) => (e) =>{
        setSearchingCategory(index);
        setSearchingType(false);
    }
    //Necesario para los canales Amazon
    const handleSearchType = (index) => (e) =>{
        setSearchingType(index);
        setSearchingCategory(false);
    }

    const handleMatchKeyword = (index) => (cat) => {
        
        let newArr = [...categories];
        newArr[index].nameMarket = cat.category;
        newArr[index].idMarket = cat.categoryId;
        newArr[index].idMarket2 = cat.categoryId2;
        setSearchingCategory(false);
        setCategories(newArr);
       
    }
    const handleMatchType = (index) => (matchType) => {
        
        let newArr = [...categories];
        newArr[index].idMarket = matchType;
        setSearchingType(false);
        setCategories(newArr);
        
    }

    const handleSave = () => {

        if(!searchingCategory){
            console.log("Mapping Category to Save...")
            console.log(categories);
            
            //Endpoint guardar
            channelService.updateCategoryMap(props.id, categories).then(
            (response) => {
                setMessage(false);
            },
            (error) => {
                setMessage("error al guardar las categorías: " + error);
            }
        );

        }
    }

    const categoryStatus = (data) => {
        if(data.idMarket) return "list-group-item list-group-item-info";
        else return "list-group-item";
    }

    return (
        
        <div className="container-fluid">
            <button 
                type="button" 
                className="btn btn-primary btn-cardRule"
                onClick={() => handleSave()}
            > Save</button>
            <p>Enlace las categorías de su feed de datos con las categorías del canal para una distribución óptima.</p>
            <div className="row justify-content-start">
                <div className="col">

                    {message && (
                    <div className="form-group">
                    <div className="alert alert-danger" role="alert">
                        {message}
                    </div>
                    </div>
                    )}

                    <div className="card searchingBox" key={"feedCategory"}> 
                        <div className="card-header bg-primary">
                            <h4 className="card-title text-white">Source Category</h4>
                        </div>
                        <div className="card-body" >
                            <div className="list-group">
                                { categories ? categories.map((d,k) => (
                                    
                                    <div className = {categoryStatus(d)} key={k}> 
                                        
                                        <h4 className="list-group-item-heading" onClick={handleSearch(k)}>{d.nameFeed}</h4>
                                        <h5 className="list-group-item-text" onClick={() => handleSave()}>{ "->" + d.nameMarket}</h5>
                                        <h6 className="list-group-item-text" onClick={() => handleSave()}>{ d.idMarket}</h6>     
                                            {
                                                props.platform === 'amazon' ?
                                                <h6 className="list-group-item-heading" onClick={handleSearchType(k)}>{"Set Type"}</h6>
                                                : null    
                                            }   
                                    </div>
                        
                                    )) : <CategorydPlace repeat={5}/>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="card" key={"marketCategory"}> 
                        <div className="card-header bg-primary">
                            <h4 className="card-title text-white">Market Category</h4>
                        </div>
                        <div className="card-body" >
                            { searchingCategory || searchingCategory === 0 ? 
                                <div className="row ">    
                                    <SearchCategories
                                        key = {'search'+searchingCategory}
                                        matchKeyword = {handleMatchKeyword(searchingCategory)}
                                        channel = {props.id}
                                    />
                                </div>   
                                    : ''
                            }
                            { searchingType || searchingType === 0 ? 
                                <div className="row ">    
                                    <SearchType
                                        key = {'search'+searchingType}
                                        matchType = {handleMatchType(searchingType)}
                                        channel = {props.id}
                                    />
                                </div>   
                                    : ''
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        
    );
  }
