import React from "react"

export const FieldCatPlace = (props) => {

    
    return(

        props.repeat ? Array.from(Array(props.repeat), (e, i) => {
            return (
                <ul className="list-group">
                    <li key={i} >
                    <span className="placeholder col-3"></span>
                    </li>
                
                </ul>
            )})
        
        : null
    );
    

}