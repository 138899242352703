import React, { useState, useEffect } from "react";
import { Mirakl } from "./markets/mirakl";
import { Oauth } from "./markets/oauth";
import channelService from "../../services/channels";

export const Settings = (props) => {

    const [message, setMessage] = useState("");
    const [settings, setSettings] = useState("");
        
    useEffect(() => {
        
        channelService.getChannelSettings(props.id).then(
        (response) => {
            setSettings(response.data.data);
            setMessage(false);
        },
        (error) => {
            setMessage("error al cargar la configuración del canal: " + error);
            
        }
        );
    }, []);

    

   const marketConfig = (type) => {

    switch(type)
    {
        case 'mirakl':
            return(<Mirakl id={props.id}/>)
        case 'oauth':
            return(<Oauth id={props.id}/>)
       
      default: return ""
        
    }
    

   }
   
    return (
        
        <div className="container-fluid">
            
            <div className="col-xs-12">

                {message && (
                <div className="form-group">
                <div className="alert alert-danger" role="alert">
                    {message}
                </div>
                </div>
                )}

                { settings && settings.type !== undefined ? marketConfig(settings.type) : null}
                
            </div>
            
        </div>
        
        
    );
  }
