import React from "react"

export const Help = (props) => {

    
    return(

      <div className="album py-5 bg-light">
          <div className="container">

            <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
              <div className="col">
                <div className="card shadow-sm">
                  <div className="card-body">
                    <h5 className="card-title">Introducción empooria</h5>
                    <p className="card-text">Sigue nuestra ayuda para dar tus primeros pasos con empooria, y conocer todo el potencial de la plataforma</p>
                    <div className="justify-content-sm-center">
                      <button type="button" className="btn btn-sm btn-outline-secondary">View More</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card shadow-sm">
                  <div className="card-body">
                    <h5 className="card-title">Guía de canales</h5>
                    <p className="card-text">Consulta los canales disponibles, y las peculiaridades de cada uno para lograr la mejor optimización en su difusión.</p>
                    <div className="justify-content-between align-items-center">
                      <button type="button" className="btn btn-sm btn-outline-secondary">View More</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card shadow-sm">
                  <div className="card-body">
                    <h5 className="card-title">Preguntas frecuentes</h5>
                    <p className="card-text">Encuentra respuestas a todas las dudas que suelen darse al usar empooria</p>
                    <div className="justify-content-between align-items-center">
                      <button type="button" className="btn btn-sm btn-outline-secondary">View More</button>
                    </div>
                  </div>
                </div>
              </div>

              
            </div>
          </div>
        </div>
    );
    

}