import React from "react";
import { useState, useEffect } from "react";

import { NavigationAdmin } from "../components/navigationAdmin";
import { Channel } from "../components/channel";

import channelService from "../services/channels";
import { CardPlace } from "../components/placeholders/card";


const Channels = () => {

    
  const [channelsData, setchannelsData] = useState("");
  const [message, setMessage] = useState("");
  
  useEffect(() => {
    channelService.getProjectChannels().then(
      (response) => {
        
        setchannelsData(response.data.data);
        if (response.data.data) {
          localStorage.setItem("channels", JSON.stringify(response.data.data));
        }

      },
      (error) => {
        setMessage("error al cargar los canales: " +error);
        
      }
    );
  }, []);

    
    return (
    
    <div>
        <NavigationAdmin/>
        <div id='dashboard' className='text-center'>
          <div className='container'>
            <div className='section-title'>
              <h2>Channels</h2>
              <p>
                Tus canales conectados. Realiza la configuración, para enviar los datos.
              </p>
            </div>
            
            { message && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {message}
                </div>
              </div>
            )}
              <div className='row'>
              { channelsData ? channelsData.map((d) => (
                    <div className="col-3" key={d.id}>
                      <Channel
                        key={d.id}
                        id={d.id}
                        status={d.status}
                        products={d.products}
                        name={d.name}
                        language={d.country}
                        description={d.description}
                        image={d.platform}
                        updated={d.updated}
                        mode="channels"
                      />
                    </div>

                )
                
                ) : <CardPlace repeat={4}/>}

              </div>
              
          </div>
    </div>
     
    
    </div> 
    );
  }
  
  export default Channels;