import { Link } from "react-router-dom";

import offPng from "../assets/off.png";
import onPng from "../assets/on.png";

export const Channel = (props) => {

  return (
    <Link className="nav-link p-0 mb-2" to={`/${props.mode}/${props.id}`} >
     
     
      
        <div className="card">
          <img className= "card-img-top" src={"img/markets/"+props.image+".jpg"} alt="Logo" />
            <div className="card-body">
              <h5 className="card-title">{props.name}</h5>
              <p className="card-text">{props.description}</p>
              <p className="card-text">{props.products} Products Optimized</p>
              <img src={"img/sources/languages/"+props.language+".png"} height="40px" alt="Logo" />
              {props.status ? (
                  <img className="cardChannel-like-icon" src={onPng} alt="Logo" />
                ) : (
                  <img className="cardChannel-like-icon" src={offPng} alt="Logo" />
                )}
              <p className="card-text">Last updated: {props.updated}</p>
            </div>
          </div>
                  

    </Link>
  );
}