import React, { useState, useEffect } from "react";
import channelService from "../../services/channels";

export const SearchField = (props) => {

    const [feedFields, setFeedFields] = useState("");
    const [keyword, setKeyword] = useState("");
    const [message, setMessage] = useState("");
    
    useEffect(() => {
        
        channelService.getSourceFields(props.source).then(
        (response) => {
            setFeedFields(response.data.data);
            setMessage(false);
        },
        (error) => {
            setMessage("error al cargar los Campos: " + error);
            
        }
        );
    }, []);

    const handleKeyword = (field) => {
        props.matchKeyword(field);
    }

    const handleChange = (e) => {
       setKeyword(e.target.value); 
    }

    return(
        <div className="col searchingBox">
            <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon1">Searching...</span>
            </div>
            <input  
                type="text" 
                name="variable" 
                className="form-control" 
                placeholder="Search Variable" 
                value={keyword}
                onChange={handleChange}
            />
            { message && (
                <div className="form-group">
                <div className="alert alert-danger" role="alert">
                    {message}
                </div>
                </div>
            )}  
            <ul className="list-group">
                { feedFields ? feedFields.filter((el) => {
                                return el.toLowerCase().indexOf(keyword.toLowerCase()) > -1;
                            })
                            .map((f)=>
                            <li 
                            key={f}
                            className="list-group-item searching-li"
                            onClick={() => handleKeyword(f)}>{f}</li>
                            )
                                
                                
                            :
                            'loading' }
                
            </ul>
        </div>

    )}