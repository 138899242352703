import React, { useState, useEffect } from "react";
import sourceService from "../../services/source";
import { TableHeaderPlace } from "../placeholders/tableheader";
import { TableRowPlace } from "../placeholders/tablerow";

export const Products = (props) => {

    const [fields, setFields] = useState("");
    const [rowfields, setRowFields] = useState("");
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [totalPages, setTotalPages] = useState("");
    const [message, setMessage] = useState("");

    
    useEffect(() => {
        
        sourceService.getSourceFields(props.source).then(
        (response) => {
            
            setFields(response.data.data);
            setMessage(false);
        },
        (error) => {
            setMessage("error al cargar los Campos: " + error);
        }
        );

        sourceService.getProductsPreview(props.source,limit, page).then(
            (response) => {
                console.log(response.data.data);
                setRowFields(response.data.data.products);
                setPage(response.data.data.page);
                setTotalPages(response.data.data.totalPages);
                setMessage(false);
            },
            (error) => {
                setMessage("error al cargar los Campos: " + error);
            }
            );

    }, []);

    const updatePreviewProduct = (limit, page) => {
        sourceService.getProductsPreview(props.source,limit, page).then(
            (response) => {
                console.log(response.data.data.page);
                setRowFields(response.data.data.products);
                setPage(response.data.data.page);
                setTotalPages(response.data.data.totalPages);
                setMessage(false);
            },
            (error) => {
                setMessage("error al cargar los Campos: " + error);
            }
        );

    }

    const handleNextPage = () => {

        if(page < totalPages){
            const nextPage = parseInt(page)+1;
            updatePreviewProduct(limit, nextPage);
        }
        else{
            //Todo
        }
    };
    const handlePrevPage = () => {

        if(page > 1){
            const prevPage = parseInt(page)-1;
            updatePreviewProduct(limit, prevPage);
        }
        else{
            //Todo
        }
    };

    
    return (
        
        <div className="container-fluid">
            <p>Esta es la información original de tus productos</p>
            
            { message && (
            <div className="form-group">
              <div className="alert alert-danger" role="alert">
                {message}
              </div>
            </div>
            )}
            
            <h6>Página {page} de {totalPages}</h6>
            <button 
                type="button" 
                className="btn btn-primary btn-cardRule"
                onClick={() => handlePrevPage()}
            > Prev Pagina</button>
            <button 
                type="button" 
                className="btn btn-primary btn-cardRule"
                onClick={() => handleNextPage()}
            > Next Pagina</button>
            
            <div className="table-responsive"> 
                <table className="table previewTable"> 
                    <thead>
                        <tr>{fields ? fields.map( (field) => 
                            <th key={"thead"+field}>{field}</th>
                            
                            ) : <TableHeaderPlace/>}</tr>
                    </thead>
                    <tbody>
                    {rowfields ? rowfields.map( (row, i) =>             
                        <tr key= {"tr"+i}> 
                            {fields ? fields.map( (field,e) => 
                            <td key={"tr"+i+"td"+e}>
                                <div className="previewCell">{row[field]}</div>
                            </td>
                            ) : <TableRowPlace/>}
                             
                        </tr>
                    ) : null}
                    </tbody>
                </table> 
            </div>
            
            
        </div>
        
        
    );
  }
