import React from "react"

export const CardPlace = (props) => {

    
    return(

        props.repeat ? Array.from(Array(props.repeat), (e, i) => {
            return (
            <div className="col-3" key={i}>
                <div className="card">
                    <img className= "card-img-top" src={"img/placeholders/card.png"} alt="" height="300px"/>
                    <div className="card-body">
                        <h5 className="card-title placeholder-glow">
                        <span className="placeholder col-6 bg-primary"></span>
                        </h5>
                        <p className="card-text placeholder-glow">
                        <span className="placeholder col-7 bg-primary"></span>
                        <span className="placeholder col-4 bg-primary"></span>
                        <span className="placeholder col-4 bg-primary"></span>
                        <span className="placeholder col-6 bg-primary"></span>
                        <span className="placeholder col-8 bg-primary"></span>
                        </p>
                    </div>
                </div>
            </div>
        )})
        
        : null
    );
    

}