import React, { useState, useEffect } from "react";
import { SearchVar } from "./searchVar";
import channelService from "../../services/channels";
import { ConversionField } from "./conversionField";

export const RulesActions = (props) => {

    const [actions, setActions] = useState(props.actions);
    const [channelFields, setChannelFields] = useState("");
    const [searchingVar, setSearchingVar] = useState(false);
    const [conversionVar, setConversionVar] = useState(false);
    const [message, setMessage] = useState("");
    

    useEffect(() => {
         setActions(actions);
            //Cargamos los campos del canal sobre los que Actuará la regla 
            channelService.getFields(props.channel).then(
            (response) => {
                setChannelFields(response.data.data);
                setMessage(false);
            },
            (error) => {
                setMessage("error al cargar los Campos: " + error);
            }
            );
    }, []);  

    const handleAdd = (e) => {
        e.preventDefault();

        const newAction = { field: "", value:"", type: "string", category: "", conversionfield: "", values: [] };
        setActions([...actions, newAction]);
        
    }

    const handleDelete = (index) => (e) => {
        e.preventDefault();
        let newArrDel = [...actions];
        newArrDel.splice(index, 1);
        setActions(newArrDel);

        props.updatedActions(newArrDel);
    }

    const handleChangeField = (index) => (e) => {
        
        let newArr = [...actions];
        
        newArr[index].category = e.target[e.target.selectedIndex].id;
        newArr[index].field = e.target.value;
        
        setActions(newArr);
        console.log(newArr);
        props.updatedActions(newArr);
        
    }
    const handleChangeValue = (index) => (e) => {
        
        let newArr = [...actions];
        newArr[index].value = e.target.value
        setActions(newArr);
        console.log(newArr);
        props.updatedActions(newArr);
        
    }

    const handleMatchKeyword = (index) => (field) => {
    
        let newArr = [...actions];
        let actualValue = newArr[index].value; 
        newArr[index].value = actualValue+field;
        
        setSearchingVar(false);
        setActions(newArr);
        
        props.updatedActions(newArr);

    }

    const handleDetectVariable = (index) => (e) =>{
        const preshKey = e.key;
        preshKey === "$" ? setSearchingVar(index) : setSearchingVar(false);
        
    }

    //Listas Conversión
    const handleConversionList = () => {
        const conversionEstatus = conversionVar;
        setConversionVar(!conversionEstatus);

    }

    //Nos devuelve la información de un field, entre ello el tipo de valores admitidos y los valores
    const fieldtype = (field) => {
          let newArr = [...channelFields];
          let fieldType = newArr.find((channelField) => {
            return channelField.name === field;
            });

            if(fieldType) return fieldType;
            else return '';
    };

    //Parse del string de idiomas del campo, trabajamos en english
    const namevalue = (names) => {
        const nameValues = JSON.parse(names)
        return nameValues.en;
    }

    const updateValues =  (index) => (updatedValues) => {
        
        console.log(updatedValues);
        let newArr = [...actions];
        newArr[index].values = updatedValues
        setActions(newArr);
        
        props.updatedActions(newArr);

        
    }

    const updateConversionField =  (index) => (conversioField) => {
        
        let newArr = [...actions];
        newArr[index].conversionfield = conversioField
        setActions(newArr);
        
        props.updatedActions(newArr);
    }


    return(
        <div> 
            {
                actions ? actions.map((action,s) => (
                <div>    
                <div className="row"  key={s}>
                    <div className="col">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="basic-addon1">Quiero Este campo</span>
                        </div>

                        { message && (
                        <div className="form-group">
                        <div className="alert alert-danger" role="alert">
                            {message}
                        </div>
                        </div>
                        )}
                        
                        <select name= "field" className="form-select" value={action.field} onChange={handleChangeField(s)}>
                            { channelFields ? channelFields.map((f)=>
                                <option value={f.name} key={f.name + f.category.idMarket} id={f.category.idMarket}>{f.name}</option>
                            ):
                            'loading' }
                        </select>

                    </div>
                    <div className="col">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="basic-addon1">Valor</span>
                        </div>
                        
                        { fieldtype(action.field).type === 'list_box' || fieldtype(action.field).type === 'check_box' ? 
                         
                         //Si el campo requiere de un valor único de una lista o checkbox, mostramos desplegable con los valores admitidos
                        <select  className="form-select" value={action.value} onChange={handleChangeValue(s)} >

                            <option value={''} key={''}>{'-select value-'}</option>
                            { fieldtype(action.field).values ? fieldtype(action.field).values.map((o)=>
                                <option value={o.id} key={o.id}>{namevalue(o.names)}</option>
                            ):
                            'loading' }
                        </select>
                        :
                        //Si el campo no requiere valores únicos, mostramos input
                        <input 
                            type="text" 
                            name="value" 
                            className="form-control" 
                            placeholder="Valor o Variable" 
                            value={action.value}
                            onChange={handleChangeValue(s)}
                            onKeyPress={handleDetectVariable(s)}
                        />

                        }
                        
                        { searchingVar === s ? 
                            <SearchVar
                                key = {'search'+s}
                                matchKeyword = {handleMatchKeyword(s)}
                                source={props.source}
                            />
                            : ''
                        }
                        
                    </div>
                    <button 
                            type="button" 
                            className="btn-close"
                            onClick={handleDelete(s)}
                        ></button>
                    <button 
                            type="button" 
                            className="btn btn-primary btn-cardRule"
                            onClick={handleConversionList}
                        >Ver/Crear lista de conversión</button>                
                </div>
                <div className="row"  key={"conversion"+s}>
                { //Movido desde debajo de searchinVar
                            conversionVar ? 
                            <ConversionField
                            key = {'conversion'+s}
                            validvalues = {fieldtype(action.field).values}
                            conversionField = {action.conversionfield}
                            updateConversionField = {updateConversionField(s)}
                            field = {action.field}
                            setValues = {action.values}
                            updateValues = {updateValues(s)}
                            source={props.source}
                            />
                            :
                            ''
                        }   
                </div>
                </div>
                )

                ) : "Loading..."
            }
        <button type="button" className="btn btn-primary btn-cardRule" onClick= {handleAdd}>
        Añadir otro campo
        </button>
        </div>
        

    )
    

}