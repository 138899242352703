import React from "react"

export const CategorydPlace = (props) => {

    
    return(

        props.repeat ? Array.from(Array(props.repeat), (e, i) => {
            return (
            
                <div className="card" key={i}>
                    
                    <div className="card-body">
                        <h4 className="card-title placeholder-glow">
                        <span className="placeholder col-6"></span>
                        </h4>
                        <h5 className="card-text placeholder-glow">
                        <span className="placeholder col-3"></span>
                        </h5>
                        <h6 className="card-text placeholder-glow">
                        <span className="placeholder col-3"></span>
                        </h6>
                        
                    </div>
                </div>
            
        )})
        
        : null
    );
    

}