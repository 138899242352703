import React, { useState, useEffect } from "react";
import channelService from "../../services/channels";
import jsonOperators from "../../data/dataOperators.json";

export const RulesCondition = (props) => {

    const [conditions, setConditions] = useState(props.conditions);
    const [feedFields, setFeedFields] = useState("");
    const [operators, setOperators] = useState(jsonOperators);
    const [message, setMessage] = useState("");

    useEffect(() => { 
        setConditions(props.conditions);
        
        channelService.getSourceFields(props.source).then(
            (response) => {
                setFeedFields(response.data.data);
                setMessage(false);
            },
            (error) => {
                setMessage("error al cargar los Campos: " + error);
            }
            );
        }, [props.conditions]);

    const handleAdd = (e) => {
        e.preventDefault();

        const newCondition = { field: "", operator:"", value:""};
        setConditions([...conditions, newCondition]);
        
     }

     const handleDelete = (index) => (e) => {
        e.preventDefault();
        let newArrDel = [...conditions];
        newArrDel.splice(index, 1);
        setConditions(newArrDel);
        props.updatedConditions(newArrDel);

     }

     const handleChange = (index) => (e) => {
         
        let newArr = [...conditions];
        newArr[index][e.target.name] = e.target.value; 
        setConditions(newArr);
        props.updatedConditions(newArr);
      }

     

    return(
        <div> 
        { conditions ? (conditions).map((d,s) => (
            <div className="row mb-3" key={s}>
                { message && (
                    <div className="form-group">
                    <div className="alert alert-danger" role="alert">
                        {message}
                    </div>
                    </div>
                )}

                <div className="col">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">Cuando Este campo</span>
                    </div>
                    
                    <select name= "field" className="form-select" value={d.field} onChange={handleChange(s)}>
                        { feedFields ? feedFields.map((f)=>
                            <option value={f} key={f}>{f}</option>
                        ):
                        'loading' }
                    </select>

                </div>
                <div className="col">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">Acción</span>
                    </div>
                    <select name= "operator" className="form-select" value={d.operator} onChange={handleChange(s)}>
                        { operators ? operators.map((o)=>
                            <option value={o} key={o}>{o}</option>
                        ):
                        'loading' }
                    </select>
                </div>
                <div className="col">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">Valor</span>
                    </div>
                    <input 
                        type="text"
                        name="value"  
                        className="form-control" 
                        placeholder={d.value} 
                        value = {d.value} 
                        onChange={handleChange(s)}/>
                </div>
                <button 
                    type="button" 
                    className="btn-close"
                    onClick={handleDelete(s)}
                ></button>
            </div>
            )
                        
            ) : "loading"
        }
        
        <button type="button" className="btn btn-primary btn-cardRule" onClick= {handleAdd}>
        Añadir otro campo
        </button>
        </div>
        

    )
    

}