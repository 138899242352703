import React, { useState, useEffect } from "react";
import sourceService from "../../services/source";
import { TableHeaderPlace } from "../placeholders/tableheader";
import { TableRowPlace } from "../placeholders/tablerow";
import { FormProduct } from "./formProduct";

export const Eproducts = (props) => {

    const [fields, setFields] = useState("");
    const [rowfields, setRowFields] = useState("");
    const [formProductView, setFormProductView] = useState(false);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [totalPages, setTotalPages] = useState("");
    const [message, setMessage] = useState("");
    const [action, setAction] = useState("");
    const [activeProduct, setActiveProduct] = useState("");

    
    useEffect(() => {
        
        sourceService.getSourceFields(props.source).then(
        (response) => {
            
            setFields(response.data.data);
            setMessage(false);
        },
        (error) => {
            setMessage("error al cargar los Campos: " + error);
        }
        );
        
        sourceService.getProductsPreview(props.source,limit, page, true).then(
            (response) => {
               // console.log(response.data.data);
                setRowFields(response.data.data.products);
                setPage(response.data.data.page);
                setTotalPages(response.data.data.totalPages);
                setMessage(false);
            },
            (error) => {
                setMessage("error al cargar los Campos: " + error);
            }
            );

    }, []);

    const addProduct = (state, action, product) => {

        setAction(action);
        setFormProductView(state);
        setActiveProduct(product);

        
    };
    //Eliminar Regla
    const deleteProduct = (product) => {

        sourceService.deleteProduct(product).then(
            (response) => {
                //eliminamos del array de Exclusiones el id eliminado TEMPORAL lo ideal seria que volvieran los datos en la respuesta.
                const productDelete = rowfields.filter(exc => exc._id !== product);

                setRowFields(productDelete);
                setMessage(false);
            },
            (error) => {
                setMessage("No se pudo eliminar el product: " + error);
                
            }
        );
    }
    
    const updatePreviewProduct = (limit, page) => {
        sourceService.getProductsPreview(props.source,limit, page, true).then(
            (response) => {
                //console.log(response.data.data.page);
                setRowFields(response.data.data.products);
                setPage(response.data.data.page);
                setTotalPages(response.data.data.totalPages);
                setMessage(false);
            },
            (error) => {
                setMessage("error al cargar los Campos: " + error);
            }
        );

    }

    const handleNextPage = () => {

        if(page < totalPages){
            const nextPage = parseInt(page)+1;
            updatePreviewProduct(limit, nextPage);
        }
        else{
            //Todo
        }
    };
    const handlePrevPage = () => {

        if(page > 1){
            const prevPage = parseInt(page)-1;
            updatePreviewProduct(limit, prevPage);
        }
        else{
            //Todo
        }
    };
    
    
    return (
        
        <div className="container-fluid">
            <p>Productos Empooria</p>
            
            { message && (
            <div className="form-group">
              <div className="alert alert-danger" role="alert">
                {message}
              </div>
            </div>
            )}
            
            { formProductView ? <FormProduct source = {props.source} product = {activeProduct} updatedProduct = {addProduct} action = {action}/> : 
            
                <div key="produtsEmpooria">
                    <h6>Página {page} de {totalPages}</h6>
                    <button 
                        type="button" 
                        className="btn btn-primary btn-cardRule"
                        onClick={() => addProduct(true, "add")}
                    > Add Product</button>
                    <button 
                        type="button" 
                        className="btn btn-primary btn-cardRule"
                        onClick={() => handlePrevPage()}
                    > Prev Pagina</button>
                    <button 
                        type="button" 
                        className="btn btn-primary btn-cardRule"
                        onClick={() => handleNextPage()}
                    > Next Pagina</button>
                    
                    <div className="table-responsive"> 
                        <table className="table previewTable"> 
                            <thead>
                                
                                <tr>
                                    <th key={"theadActions"}>Acciones</th>
                                    {fields ? fields.map( (field) => 
                                    <th key={"thead"+field}>{field}</th>
                                    
                                    ) : <TableHeaderPlace/>}</tr>
                            </thead>
                            <tbody>
                                
                            {rowfields ? rowfields.map( (row, i) =>             
                                <tr key= {"tr"+i}> 
                                    <td>
                                    <button 
                                        type="button" 
                                        className="btn btn-primary btn-cardRule"
                                        onClick={() => addProduct(true, "edit", row._id)}
                                    > Edit</button>
                                    <button 
                                        type="button" 
                                        className="btn btn-danger btn-cardRule"
                                        onClick={e =>
                                            window.confirm("Seguro que quieres Eliminar esta Producto?") &&
                                            deleteProduct(row._id)
                                        }
                                    > Delete</button>
                                    </td>
                                    {fields ? fields.map( (field,e) => 
                                    <td key={"tr"+i+"td"+e}>
                                        <div className="previewCell">{row[field]}</div>
                                    </td>
                                    ) : <TableRowPlace/>}
                                    
                                </tr>
                            ) : null}
                            </tbody>
                        </table> 
                    </div>
                </div>
            }  
            
            
        </div>
        
        
    );
  }
