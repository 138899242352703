import axios from "axios";
import authHeader from "./auth-header";
import config from "../config";

const API_URL = config.hostname;

const getProjectChannels = () => {
    return axios.get(API_URL + "channels", { headers: authHeader() });
};

const getChannelInfo = (channel) => {
    const channels = JSON.parse(localStorage.getItem("channels"));
    const channelInfo = channels.filter((itm) => {
      return itm.id === channel;
    });
    return channelInfo[0];
};

//Dashboard
const dashboardChannels = () => {
  return axios.get(API_URL + "channels/dashboard", { headers: authHeader() });
};
//

//Categories
const getCategoryMap = (channel) => {
  return axios.get(API_URL + "channels/category/" + channel, { headers: authHeader() });
};
const updateCategoryMap = (channel, categoryMap) => {
  const data = { channel, categoryMap};
  return axios.put(API_URL + "channels/category", data, { headers: authHeader() });
};
const postSearchCategories = (channel, keywords) => {
  const data = {channel, keywords};
  return axios.post(API_URL + "categories/search", data, { headers: authHeader() });
};
const postSearchType = (channel) => {
  const data = {channel};
  return axios.post(API_URL + "categories/searchtype", data, { headers: authHeader() });
};
//End  Categories

//Fields and Attributes
const getFieldsMap = (channel) => {
  return axios.get(API_URL + "channels/fieldsmap/" + channel, { headers: authHeader() });
};
const updateFieldsMap = (channel, mapping) => {
  const data = {channel, mapping};
  return axios.put(API_URL + "channels/fieldsmap", data, { headers: authHeader() });
};
const getFields = (channel) => {
  return axios.get(API_URL + "channels/fields/" + channel, { headers: authHeader() });
};
//End Fields and Attributes

//Source
const getSourceFields = (source) => {
  return axios.get(API_URL + "imports/source/fields/" + source, { headers: authHeader() });
};
//End Source

//Rules
const getRulesField = (channel, field, category) => {
  return axios.get(API_URL + "rules/field/" + channel + "/" + field + "/category/" + category, { headers: authHeader() });
};
const getRule= (channel, rule) => {
  return axios.get(API_URL + "rules/" + channel + "/" + rule, { headers: authHeader() });
};
const postRule = (channel, field, category) => {
  const data = {channel, field, category};
  return axios.post(API_URL + "rules/", data, { headers: authHeader() });
};
const editRule = (rule) => {
  return axios.put(API_URL + "rules/", rule, { headers: authHeader() });
};
const editRulesOrder = (rules) => {
  return axios.put(API_URL + "rules/order", rules, { headers: authHeader() });
};
const deleteRule = (rule) => {
  const data = {rule};
  return axios.post(API_URL + "rules/delete", data, { headers: authHeader() });
};
//End Rules

//Preview
const getOptimizedFeed = (channel, limit, page) => {
  return axios.get(API_URL + "feed/" + channel + "/preview?limit=" + limit + "&page=" + page, { headers: authHeader() });
};
const generateOptimizedFeed = (channel, limit, page) => {
  return axios.get(API_URL + "feed/" + channel, { headers: authHeader() });
};
//End Preview

//Exclusion
const addExclusion = (channel) => {
  const data = {channel};
  return axios.post(API_URL + "exclusions/", data, { headers: authHeader() });
};
const getChannelExclusions = (channel) => {
  return axios.get(API_URL + "exclusions/" + channel, { headers: authHeader() });
};
const getExclusion= (channel, exclusion) => {
  return axios.get(API_URL + "exclusions/" + channel + "/" + exclusion, { headers: authHeader() });
};
const editExclusion = (exclusion) => {
  return axios.put(API_URL + "exclusions/", exclusion, { headers: authHeader() });
};
const deleteExclusion = (exclusion) => {
  const data = {exclusion};
  return axios.post(API_URL + "exclusions/delete", data, { headers: authHeader() });
};
//End Exclusions

//Settings Channel
const getChannelSettings = (channel) => {
  return axios.get(API_URL + "channels/settings/" + channel, { headers: authHeader() });
};

const editChannelSettings = (channel, type, settings) => {
  const data = {
    channel,
    type,
    settings
  }
  return axios.put(API_URL + "channels/settings", data, { headers: authHeader() });
}

//End Settings Channel

//Reports
const getChannelReports = (channel) => {
  return axios.get(API_URL + "reports/list/" + channel, { headers: authHeader() });
};
const getErrorReport = (report) => {
  return axios.get(API_URL + "reports/error/" + report, { headers: authHeader() });
};

//Fin Reports

export default {
    getProjectChannels,
    dashboardChannels,
    getChannelInfo,
    getCategoryMap,
    updateCategoryMap,
    postSearchCategories,
    postSearchType,
    getFieldsMap,
    updateFieldsMap,
    getRulesField,
    getRule,
    getFields,
    getSourceFields,
    postRule,
    editRule,
    deleteRule,
    editRulesOrder,
    getOptimizedFeed,
    generateOptimizedFeed,
    addExclusion,
    getChannelExclusions,
    getExclusion,
    editExclusion,
    deleteExclusion,
    getChannelSettings,
    editChannelSettings,
    getChannelReports,
    getErrorReport,
    
  };