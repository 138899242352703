import React, { useState, useEffect } from "react";

import { ExclusionCondition } from "./exclusionConditions";

export const ExclusionGlobalCondition = (props) => {

    const [globalConditions, setglobalConditions] = useState(props.globalConditions);

    useEffect(() => { 
        setglobalConditions(props.globalConditions) 
    }, [props.globalConditions]);

    const handleDelete = (index) => (e) => {
        e.preventDefault();
        let newArrDel = [...globalConditions]; 
        
        newArrDel.splice(index, 1);
        setglobalConditions(newArrDel);
        props.updatedGlobalConditions(newArrDel);

     }

    const updatedConditionsAny = (index) =>(conditionsAny) =>{
        
        let newArr = [...globalConditions]; 
        newArr[index].any = conditionsAny; 
        setglobalConditions(newArr)
        props.updatedGlobalConditions(newArr)
    }

    const updatedConditionsAll = (index) => (conditionsAll) =>{
        
        let newArr = [...globalConditions]; 
        newArr[index].all = conditionsAll; 
        setglobalConditions(newArr)
        props.updatedGlobalConditions(newArr)
    }
     

    return(
        <div> 
        {
                globalConditions ? (globalConditions).map((l,i) => (
                    <div key={i}>   
                        

                        { l.all ? 
                        <div className="card mb-3" >
                        <div className="card-header bg-info text-white d-flex">
                        <h4>Cuando se cumplan Todas estas condiciones</h4> 
                        <button 
                            type="button" 
                            className="btn-close"
                            onClick={handleDelete(i)}
                        ></button>
                        </div>    
                        <div className="card-body" >
                        <ExclusionCondition 
                            conditions={l.all}
                            updatedConditions={updatedConditionsAll(i)}
                            key={"all"+i}
                            source={props.source}
                        />
                        </div>

                        </div>
                        : ''}

                        { l.any ?
                        <div className="card mb-3" >
                        <div className="card-header bg-info text-white d-flex">
                        <h4>Cuando se cumpla Alguna de estas condiciones</h4> 
                        <button 
                            type="button" 
                            className="btn-close"
                            onClick={handleDelete(i)}
                        ></button>
                        </div>
                        <div className="card-body" >
                        <ExclusionCondition 
                            conditions={l.any}
                            updatedConditions={updatedConditionsAny(i)}
                            key={"any"+i}
                            source={props.source}
                        />
                        </div>
                        </div>
                        : ''}

                    
                    
                    </div>    

                    
                ))
                :''
            }
        
        </div>
        

    )
    

}