import React from "react";
import { useState, useEffect } from "react";
import { ExclusionGlobalCondition } from "./exclusionGlobalConditions";
import channelService from "../../services/channels";

export const Exclusion = (props) => {

    const [exclusionData, setExclusionData ] = useState("");
    const [editTitle, setEditTitle] = useState(false);
    const [message, setMessage] = useState("");

    useEffect(() => {
        channelService.getExclusion(props.id, props.exclusion).then(
        (response) => {
            console.log(response.data);
            setExclusionData(response.data.data);
            setMessage(false);
        },
        (error) => {
            setMessage("error al cargar los Campos: " + error);
            
        }
        );
    }, []);
   
    const updatetitle =  (title) => {
        
        let newObj = {...exclusionData}; 
        newObj.name= title; 
        setExclusionData(newObj)
        
    }
    const handleTitle = (e) => {
        
        const title = e.target.value;
        updatetitle(title);
        
      }

    
    const updatedGlobalConditionsAny = (globalConditionsAny) =>{
        
        let newArr = {...exclusionData};
        newArr.conditions.any = globalConditionsAny; 
        setExclusionData(newArr)
    }

    const updatedGlobalConditionsAll = (globalConditionsAll) =>{
        
        let newArr = {...exclusionData};
        newArr.conditions.all = globalConditionsAll; 
        setExclusionData(newArr)
        
    }

    const addGlobalCondition = (globalConditional, conditional) => {
        
        let newObj = {...exclusionData};
        let newGlobalCondition = '';

        switch(conditional){

            case 'any':
                newGlobalCondition = {any:[{field : "",operator : "",value : ""}]};
                break;
            case 'all':
                newGlobalCondition = {all:[{field : "",operator : "",value : ""}]};
                break;
        }
        switch(globalConditional){

            case 'any':
                newObj.conditions.any.push(newGlobalCondition);
                break;
            case 'all':
                newObj.conditions.all.push(newGlobalCondition);
                break;
        }
        setExclusionData(newObj);
       
    }
    

    const updateExclusion = () =>{
        
        channelService.editExclusion(exclusionData).then(
            (response) => {
                setMessage(false);
            },
            (error) => {
                setMessage("error al guardar la regla: " + error);
            }
        );
            
    }

   

    

    return (
        <div>
        { exclusionData ?
        <div className="card">
            
           <div className="card-header d-flex align-items-center">
                <h5>{exclusionData.name} ({props.exclusion})</h5>
                <button type="button" className="btn btn-primary btn-cardRule" onClick={() => (setEditTitle(true))} >Edit Name</button>
                
            </div>
            
            {editTitle ? 
            <div className="card-header">
                <input 
                    type="text" 
                    name="ruleName"
                    className="form-control" 
                    placeholder="Name of the Rule" 
                    value={exclusionData.name}
                    onChange={handleTitle}
                    onBlur={() => (setEditTitle(false))}
                            
                />
            </div>
            : ''}
            
            <div className="card-body">
                {message && (
                <div className="form-group">
                <div className="alert alert-danger" role="alert">
                    {message}
                </div>
                </div>
                )}
                {exclusionData.conditions.any ? 
                    <div className= "card">
                        <div className="card-header bg-primary text-white">
                        <h5>Excluir cuando se cumpla alguna de estas Reglas</h5> 
                        </div>
                        <div className="card-body" >
                        {
                            exclusionData.conditions.any ? 
                            <ExclusionGlobalCondition
                                idRule= {props.idRule}
                                globalConditions = {exclusionData.conditions.any}
                                updatedGlobalConditions = {updatedGlobalConditionsAny}
                                key="ANY"
                                source={props.source}
                            />    

                            :''
                        }
                        <button 
                            type="button" 
                            className="btn btn-primary btn-cardRule"
                            onClick={() => addGlobalCondition('any','all')}
                        >
                        AND
                        </button>
                        <button 
                            type="button" 
                            className="btn btn-primary btn-cardRule"
                            onClick={() => addGlobalCondition('any','any')}
                        >
                        OR
                        </button>
                        </div>
                    </div>
                : ''}

                {exclusionData.conditions.all ?
                    <div className= "card">
                        <div className="card-header bg-primary text-white">
                        <h5>Excluir cuando se cumplan Todas estas Reglas</h5> 
                        </div>
                        <div className="card-body" >
                        {
                            exclusionData.conditions.all ? 
                            <ExclusionGlobalCondition 
                            idRule= {props.idRule}
                            globalConditions = {exclusionData.conditions.all}
                            updatedGlobalConditions = {updatedGlobalConditionsAll}
                            key="ALL"
                            source={props.source}
                            />    

                            :''
                        }
                        <button 
                            type="button" 
                            className="btn btn-primary btn-cardRule"
                            onClick={() => addGlobalCondition('all','all')}
                            >
                        AND
                        </button>
                        <button 
                            type="button" 
                            className="btn btn-primary btn-cardRule"
                            onClick={() => addGlobalCondition('all','any')}
                            >
                        OR
                        </button>
                        </div>
                    </div>
                : ''}
                
            
                <button type="button" className="btn btn-primary btn-cardRule" onClick= {updateExclusion}>
                Guardar
                </button>
            </div>
            
        </div>
        : "loading" }   
        </div>        
        
        
        
        
    );

  }
