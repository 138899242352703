import React, { useState, useEffect } from "react";
import channelService from "../../services/channels";

export const Historic = (props) => {

    const [reportsList, setReportsList] = useState("");
    const [message, setMessage] = useState("");

    
    useEffect(() => {
        
        channelService.getChannelReports(props.id).then(
            (response) => {
                
                setReportsList(response.data.data);
                setMessage(false);
            },
            (error) => {
                setMessage("error al cargar los informes: " + error);
            }
            );

    }, []);


    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
      
        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
      }

      const exportToJson = async (report) => {
       // e.preventDefault()
       const response = await channelService.getErrorReport(report);
       if(response.data.data.result.errorList){

       }
       //descargar datos

        downloadFile({
          data: JSON.stringify(response.data.data.result.errorList),
          fileName: report+'.json',
          fileType: 'text/json',
        })
      }

      const exportToCsv = async (report) => {
        //e.preventDefault();
        const response = await channelService.getErrorReport(report);
        //console.log(response);
        const errorList = response.data.data.result.errorList;
        //console.log(errorList);
        const parsedJson = errorList;//JSON.parse(errorList);
        if (
          !Array.isArray(parsedJson) ||
          !parsedJson.every((p) => typeof p === "object" && p !== null)
        ) {
          return;
        }
        if(parsedJson.length > 0){
          const heading = Object.keys(parsedJson[0]).join(";");
          const body = parsedJson.map((j) => Object.values(j).join(";")).join("\n");
          
          //Descarga
          downloadFile({
              data: `${heading}${"\n"}${body}`,
              fileName: report+'.csv',
              fileType: 'text/csv',
            })
          }
          //No hay errores
         else{
          downloadFile({
            data: `No errors`,
            fileName: report+'.csv',
            fileType: 'text/csv',
          })
         } 
        


        
      };

    
    return (
        
        <div className="container-fluid">
            <p>Histórico de las últimas publicaciones en el canal:</p>
            
            { message && (
            <div className="form-group">
              <div className="alert alert-danger" role="alert">
                {message}
              </div>
            </div>
            )}
            
            
            <div className="table-responsive"> 
                <table className="table previewTable"> 
                    <thead>
                        <tr>
                            <th>ID Report</th>
                            <th>Fecha</th>
                            <th>Tipo</th>
                            <th>Estado</th>
                            <th>Acciones</th>
                            
                        </tr>
                    </thead>
                    <tbody>
                        {reportsList ? reportsList.map((reports) => 
                        <tr key={reports._id}> 
                            <td>{reports._id}</td>
                            <td>{reports.updatedAt}</td>
                            <td>{reports.result ? reports.result.type : null}</td>
                            <td>{reports.status}</td>
                            <td>
                                { reports.status === 'PROCESSED' ?
                                <div key= {"download"+reports._id}>
                                <button 
                                type="button" 
                                className="btn btn-primary"
                                onClick={() => exportToCsv(reports._id)}
                                >CSV</button>
                                <button 
                                type="button" 
                                className="btn btn-primary"
                                onClick={() => exportToJson(reports._id)}
                                >JSON</button>
                                </div>
                                
                                
                                : null }
                            </td>
                        </tr>
                        )
                    : null }
                    </tbody>
                </table> 
            </div>
            
            
        </div>
        
        
    );
  }
