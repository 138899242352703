import React, { useState, useEffect } from "react";
import sourceService from "../../services/source";

export const FormProduct = (props) => {

    //const [conditions, setConditions] = useState(props.conditions);
    //const [feedFields, setFeedFields] = useState("");
    const [productData, setProductData] = useState("");
    
    const [message, setMessage] = useState("");
    
    useEffect(() => { 
        if(props.action === "add"){
            console.log("add");
            sourceService.getSourceFields(props.source).then(
                (response) => {
                    
                    productArray(response.data.data);
                    //console.log(response.data.data);
                    setMessage(false);
                },
                (error) => {
                    setMessage("error al cargar los Campos: " + error);
                }
            );  
        }

        else if(props.action === "edit"){

            console.log("edit");
            console.log(props.product);
            //Cargar la info del producto en productData
            sourceService.getProduct(props.source, props.product).then(
                (response) => {
                    
                    objectToArray(response.data.data);
                    console.log(response.data.data);
                    setMessage(false);
                },
                (error) => {
                    setMessage("error al cargar los Campos: " + error);
                }
            );  
        }
        
        }, []);

    const productArray = (fields) => {

        let product = [];
        if(fields)
        {
            fields.map( (field) => {
                product.push({
                    value: "",
                    field
                });

            })
        }
        setProductData(product);

        return product;


    }

    const objectToArray = (object) => {
        let product = [];

        if(object){
             Object.keys(object).map((key) => product.push({field : key, value: object[key]}));

        }
        setProductData(product);

    }
    
     const handleChange = (index) => (e) => {
         
        let newArr = [...productData];
        newArr[index].value = e.target.value; 
        setProductData(newArr);
        //props.updatedConditions(newArr);
      }

      const handleSave = () => {
         
        //console.log(productData);
        //Enviar a API
        sourceService.updateProduct(props.source, productData).then(
            (response) => {
                setMessage(false);
                //console.log(response.data.data);
            },
            (error) => {
                setMessage("error al crear/actualizar el producto: " + error);
            }
        );

        props.updatedProduct(false, "", "");
      }

      const hiddenField = (field) =>{
        const hidden = ['_id'];
        if(hidden.includes(field)){
            return true
        }
        else return false
      }
    
     

    return(
        <div> 
        { message && (
                <div className="form-group">
                <div className="alert alert-danger" role="alert">
                    {message}
                </div>
                </div>
        )}
        <button 
            type="button" 
            className="btn btn-primary btn-cardRule"
            onClick={() => handleSave()}
        > Save Product</button>
        { productData ? (productData).map((product,s) => (
            <div className="row mb-3" key={s} hidden= {hiddenField(product.field)}>
                

                
                <div className="col">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">{product.field}</span>
                    </div>
                    <input 
                        type="text"
                        name="value"  
                        className="form-control" 
                        placeholder={product.field} 
                        value = {product.value} 
                        onChange={handleChange(s)}/>
                </div>
                
            </div>
            )
                        
            ) : "loading e"
        }
        
        
        </div>
        

    )
    

}