import React, { useState, useEffect } from "react";
import channelService from "../../../services/channels";

export const Mirakl = (props) => {

    const [message, setMessage] = useState("");
    const [settings, setSettings] = useState("");
    const [updateCredentials, setUpdateCredentials] = useState({"sesionKey":"", "idshop":""});
   
    useEffect(() => {
        
        channelService.getChannelSettings(props.id).then(
        (response) => {
            setSettings(response.data.data);
            setMessage(false);
        },
        (error) => {
            setMessage("error al cargar la configuración del canal: " + error);
        }
        );
    }, []);
    

    const itsConnect = (credentials) => {
        if(credentials) return "*****"
        else return "";

    }
    const handleChange = (e) => {
         
        let newObj = {...updateCredentials};
        const index = e.target.name;
        newObj[index] = e.target.value
        setUpdateCredentials(newObj);
        
        
      }
   
    
    const handleSave = () => {

        if(updateCredentials){
            console.log("Credentials to Save...")
            console.log(updateCredentials);
            
            //Endpoint actualizar
            channelService.editChannelSettings(props.id, 'mirakl', updateCredentials).then(
            (response) => {
                setMessage(false);
            },
            (error) => {
                setMessage("error al guardar las categorías: " + error);
            }
        );

        }
    }

    

    return (
        
        
        <div className="row">
            {message && (
                <div className="form-group">
                <div className="alert alert-danger" role="alert">
                    {message}
                </div>
                </div>
            )}
            <p>Ingrese sus Credenciales</p>

            { settings ? 
            <div className="col-xs-12">
            <label htmlFor="sesionKey">Sesion Key</label>
            <input 
                name="sesionKey"
                type="text" 
                className="form-control input-lg" 
                placeholder={itsConnect(settings.credentials)}
                onChange={handleChange}
                value = {updateCredentials.sesionKey}
            />
            <label htmlFor="idshop">Id Shop</label>
            <input 
                name="idshop"
                type="text" 
                className="form-control input-lg" 
                placeholder={itsConnect(settings.credentials)}
                onChange={handleChange}
                value = {updateCredentials.idshop}
            />
            <button 
                className="btn btn-primary btn-lg btn-block" 
                type="button" 
                onClick={e =>
                    window.confirm("Seguro que quieres Actualizar los credenciales?") && handleSave()}
                
                >Actualizar
            </button>
            </div>
            : null
            }
            
        </div>
        
        
    );
  }
