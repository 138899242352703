import React from "react";
import { useState, useEffect } from "react";

import { NavigationAdmin } from "../components/navigationAdmin";
//import Jsonfeeds from "../data/dataFeeds.json"; //De endpoint
import { Source } from "../components/source";
import sourceService from "../services/source";
import { CardPlace } from "../components/placeholders/card";


const Sources = () => {

  
  const [sourcesData, setSourcesData] = useState("");
  const [message, setMessage] = useState("");

    useEffect(() => {
      sourceService.getSources().then(
        (response) => {
          
          setSourcesData(response.data.data);
          //if (response.data.data) {
            //localStorage.setItem("channels", JSON.stringify(response.data.data));
          //}
  
        },
        (error) => {
          setMessage("error al cargar los sources: " +error);
          
        }
      );
        
    }, []);

    return (
    
    <div>
        <NavigationAdmin/>
        <div id='dashboard' className='text-center'>
          <div className='container'>
            <div className='section-title'>
              <h2>Sources</h2>
              <p>
                Origen de datos conectadas.
              </p>
            </div>
            <div className='row'>
            { message && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {message}
                </div>
              </div>
            )}
              <div className='row' >
                  { sourcesData ? sourcesData.map((d) => (
                    
                    <div className="col-3" key={"source"+d._id}>
                      <Source
                        key={d.id}
                        id={d._id}
                        //status={d.status}
                        url={d.url}
                        name={d.name}
                        language ={d.language}
                        type={d.type}
                        
                        updated={d.updatedAt}
                        mode={d.mode}
                        path="sources"
                      />
                    </div>

                )
                
                ) : <CardPlace repeat={4}/>}
              </div>
            </div>
          </div>
    </div>
     
    
    </div> 
    );
  }
  
  export default Sources;