import React, { useState, useEffect } from "react";
import sourceService from "../../services/source";

export const Settings = (props) => {

    const [dataSource, setDataSource] = useState("");
    const [sourceFields, setSourceFields] = useState("");
    const [message, setMessage] = useState("");

    //Amazon Source
    const [skuList, setSkuList] = useState("");
    const [marketplaceId, setMarketplaceId] = useState("");
    //CSV Source Parse Config
    const [csvParseConfig, setCsvParseConfig] = useState("");
    

    
    useEffect(() => {
        
        sourceService.getSource(props.source).then(
        (response) => {
            
            const { name, language, mode, type, protocol, url, uniquesku, category, parentsku, parserConfig, amazonConfig, mapping } = response.data.data;
            
            setSourceFields(mapping);
            setDataSource({ name, language, mode, type, protocol, url, uniquesku, category, parentsku, parserConfig, amazonConfig });
            setCsvParseConfig(parserConfig);
            const skuListCSV = amazonConfig.skuList.join(",");
            setSkuList(skuListCSV);
            setMarketplaceId(amazonConfig.marketplaceId);
            setMessage(false);
        },
        (error) => {
            setMessage("error al cargar los Campos: " + error);
        }
        );

        
    }, []);

    const handleChange = (e) => {
         
        let newObj = {...dataSource};
        const index = e.target.name;
        newObj[index] = e.target.value
        setDataSource(newObj);
        console.log(newObj);
        
    }
    const handleChangeCsv = (e) => {
         
        let newObj = {...csvParseConfig};
        const index = e.target.name;
        newObj[index] = e.target.value
        setCsvParseConfig(newObj);
        //console.log(newObj);
        
    }
    
    const handleSkuList = (e) => {
         
        let newCSV = e.target.value;
        setSkuList(newCSV);
    }


    const updateSource = () =>{
        
        //Si es typo Amazon
        if(dataSource.type === 'amazon'){
            const skuEspaces = skuList.replace(/\s+/g, '') 
            const match = skuEspaces.split(',')
            dataSource.amazonConfig.skuList = match;
            
        }
        if(dataSource.type === 'csv'){
            dataSource.parserConfig = csvParseConfig;
        }

        console.log(dataSource);
        sourceService.updateSource(props.source, dataSource).then(
            (response) => {
                //console.log(response.data.data);
                setMessage(false);
            },
            (error) => {
                setMessage("error al guardar la regla: " + error);
            }
        );
        

    }

    
    return (
        
        <div className="container-fluid">
            <p>Configuración del Source</p>
            
            { message && (
            <div className="form-group">
              <div className="alert alert-danger" role="alert">
                {message}
              </div>
            </div>
            )}

                        
            { dataSource ? 
                <div> 
                
                    <div className="row">
                        
                        <div className="col-6">
                        <div className="card"> 
                        <div className="card-header bg-primary text-white">
                            Configuración Fuente de datos
                        </div>
                        <div className="card-body">
                            <label htmlFor="name">Nombre del Source</label>
                            <input 
                                name="name"
                                type="text" 
                                className="form-control input-lg" 
                                placeholder="Nombre para el Source"
                                onChange={handleChange}
                                value = {dataSource.name}
                            />
                            <label htmlFor="name">Idioma del Source</label>
                            <input 
                                name="name"
                                type="text" 
                                className="form-control input-lg" 
                                placeholder="Idioma del Source"
                                onChange={handleChange}
                                value = {dataSource.language}
                            />

                            <label htmlFor="mode">Mode</label>
                            <input 
                                name="mode"
                                type="text" 
                                className="form-control input-lg" 
                                placeholder="Mode"
                                onChange={handleChange}
                                value = {dataSource.mode}
                            />

                            <label htmlFor="type">Type</label>
                            <input 
                                name="type"
                                type="text" 
                                className="form-control input-lg" 
                                placeholder="Type"
                                onChange={handleChange}
                                value = {dataSource.type}
                            />
                            
                            { dataSource.type === 'csv' && csvParseConfig ? 
                            <div key='csvConfig'>
                            <label htmlFor="protocol">Protocol</label>
                            <input 
                                name="protocol"
                                type="text" 
                                className="form-control input-lg" 
                                placeholder="protocolo"
                                onChange={handleChange}
                                value = {dataSource.protocol}
                            />

                            <label htmlFor="url">Url</label>
                            <input 
                                name="url"
                                type="text" 
                                className="form-control input-lg" 
                                placeholder="url"
                                onChange={handleChange}
                                value = {dataSource.url}
                            />
                                <div className="accordion m-3" id="accordionCSV">
                                    <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            Configuración CSV
                                        </button>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionCSV">
                                    <div className="accordion-body">
                                        <label htmlFor="delimiter">Delimitador</label>
                                        <input 
                                            name="delimiter"
                                            type="text" 
                                            readOnly={true}
                                            className="form-control input-lg" 
                                            placeholder="Delimitador"
                                            onChange={handleChangeCsv}
                                            value = {csvParseConfig.delimiter}
                                        />
                                        <label htmlFor="quote">Quote</label>
                                        <input 
                                            name="quote"
                                            type="text" 
                                            readOnly={true}
                                            className="form-control input-lg" 
                                            placeholder="quote"
                                            onChange={handleChangeCsv}
                                            value = {csvParseConfig.quote}
                                        />
                                        <label htmlFor="escape">Escape</label>
                                        <input 
                                            name="escape"
                                            type="text" 
                                            readOnly={true}
                                            className="form-control input-lg" 
                                            placeholder="escape"
                                            onChange={handleChangeCsv}
                                            value = {csvParseConfig.escape}
                                        />
                                        <label htmlFor="rowDelimiter">row Delimiter</label>
                                        <input 
                                            name="rowDelimiter"
                                            type="text" 
                                            readOnly={true}
                                            className="form-control input-lg" 
                                            placeholder="rowDelimiter"
                                            onChange={handleChangeCsv}
                                            value = {csvParseConfig.rowDelimiter}
                                        />
                                        <label htmlFor="includeEndRowDelimiter">include End Row Delimiter</label>
                                        <input 
                                            name="includeEndRowDelimiter"
                                            type="text" 
                                            readOnly={true}
                                            className="form-control input-lg" 
                                            placeholder="includeEndRowDelimiter"
                                            onChange={handleChangeCsv}
                                            value = {csvParseConfig.includeEndRowDelimiter}
                                        />
                                        <label htmlFor="writeBOM">writeBOM</label>
                                        <input 
                                            name="writeBOM"
                                            type="text" 
                                            readOnly={true}
                                            className="form-control input-lg" 
                                            placeholder="writeBOM"
                                            onChange={handleChangeCsv}
                                            value = {csvParseConfig.writeBOM}
                                        />
                                        <label htmlFor="quoteColumns">quoteColumns</label>
                                        <input 
                                            name="quoteColumns"
                                            type="text" 
                                            readOnly={true}
                                            className="form-control input-lg" 
                                            placeholder="quoteColumns"
                                            onChange={handleChangeCsv}
                                            value = {csvParseConfig.quoteColumns}
                                        />
                                        <label htmlFor="quoteHeaders">quoteHeaders</label>
                                        <input 
                                            name="quoteHeaders"
                                            type="text" 
                                            readOnly={true}
                                            className="form-control input-lg" 
                                            placeholder="quoteHeaders"
                                            onChange={handleChangeCsv}
                                            value = {csvParseConfig.quoteHeaders}
                                        />
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : null}
                            { dataSource.type === 'amazon' ? 
                            <div className="accordion m-3" id="accordionAMZ">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingOne">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        Configuración Amazon
                                    </button>
                                </h2>
                                <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionAMZ">
                                    <div className="accordion-body">
                                        <div key='amazonSkuList'>
                                            <label htmlFor="skulist">Sku List</label>
                                            <textarea 
                                                name="skulist"
                                                
                                                rows={5}
                                                className="form-control input-lg" 
                                                placeholder="skulist"
                                                onChange={handleSkuList}
                                                value = {skuList}
                                            />
                                            <label htmlFor="amzMarketplaceId">Marketplace Id</label>
                                            <input 
                                                name="amzMarketplaceId"
                                                type="text" 
                                                readOnly= {true}
                                                className="form-control input-lg" 
                                                placeholder="MarketplaceId"
                                                //onChange={handleChange}
                                                value = {marketplaceId}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            </div>
                            : null}

                            <label htmlFor="uniquesku">Campo con Valor único del Feed de origen:</label>
                            <select name= "uniquesku" className="form-select input-lg" value={dataSource.uniquesku} onChange={handleChange}>
                                { sourceFields ? sourceFields.map((f)=>
                                    <option value={f.from} key={f.from}>{f.from}</option>
                                ):
                                'loading' }
                            </select>

                            <label htmlFor="category">Campo Categoría del Feed de origen:</label>
                            <select name= "category" className="form-select input-lg" value={dataSource.category} onChange={handleChange}>
                                { sourceFields ? sourceFields.map((c)=>
                                    <option value={c.from} key={c.from}>{c.from}</option>
                                ):
                                'loading' }
                            </select>

                            <label htmlFor="parentsku">Campo ParentSKU del Feed de origen:</label>
                            <select name= "parentsku" className="form-select input-lg" value={dataSource.parentsku} onChange={handleChange}>
                                { sourceFields ? sourceFields.map((c)=>
                                    <option value={c.from} key={c.from}>{c.from}</option>
                                ):
                                'loading' }
                            </select>

                        </div>    
                        </div>            
                        </div>
                        <div className="col-6">
                            <div className="card"> 
                                <div className="card-header bg-primary text-white">
                                    Ayuda Fuente de datos
                                </div>
                                <div className="card-body">    
                                    <p>Ayuda para el Source</p>
                                    <p>La variable única es imprescindible. Requiere un campo del source con un valor único.</p>
                                    <p>La variable parentsku es necesaria si los productos tienen variaciones, de no tenerlos se indicará la misma que el valor único.</p>
                                    <button 
                                        className="btn btn-primary btn-lg btn-block" 
                                        type="button" 
                                        onClick={e =>
                                            window.confirm("¿Seguro que quieres Actualizar la configuración del Source?") &&
                                            updateSource()}>Update Source</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    
                    
                    
                </div>
            : <div className='spinner'></div>
            }
            
            
        </div>
        
        
    );
  }
