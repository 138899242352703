import { Link } from "react-router-dom";

export const Source = (props) => {
  return (
    <Link className="nav-link p-0" to={`/${props.path}/${props.id}`} >
      <div className="card">
        <img className="rounded mx-auto d-block" src={"img/sources/url"+props.type+".png"}  width="200px" alt="Logo" />
        <div className="card-body">
          <h5 className="card-title">{props.name}</h5>
          <p className="card-text">{props.id}</p>
          <p className="card-text">Type: {props.type}</p>
          <p className="card-text">Mode: <b>{props.mode}</b></p>
          <p className="card-text">URL: {props.url}</p>
          <img src={"img/sources/languages/"+props.language+".png"} height="40px" alt="Logo" />
          
          <p className="card-text">Last updated: {props.updated}</p>
        </div>
      </div>
    </Link>
  );
}