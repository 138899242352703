import { Link } from "react-router-dom";

export const NavigationSource = (props) => {
  return (
  <nav className="navbar navbar-expand-lg navbar-light  bg-light bg-gradient shadow-sm">
    <div className="container-fluid">
      
      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
          
        <li>
        <Link className="nav-link" to={`/sources/${props.id}/productos`}>Ver Productos</Link>
        </li>
        <li>
        <Link className="nav-link" to={`/sources/${props.id}/historic`}>Histórico</Link>
        </li>
        <li>
        <Link className="nav-link" to={`/sources/${props.id}/settings`}>Ajustes</Link>
        </li>
        <li>
        <Link className="nav-link" to={`/sources/${props.id}/eproducts`}>Empooria Products</Link>
        </li>
        
          
        </ul>
        
      </div>
    </div>
  </nav>
  )
}


