import React from "react";

import { useParams } from "react-router-dom";
import { Products } from "../components/sources/products"
import { Settings } from "../components/sources/settings";
import { Historic } from "../components/sources/historic";
import { Eproducts } from "../components/sources/eproducts";

import { NavigationAdmin } from "../components/navigationAdmin";
import { NavigationSource } from "../components/navigationSource";



const Source = () => {

  

  const { source, path } = useParams();
  
  const modePath = (path, source) => {

    switch(path)
    {
      case 'productos':
        return(<Products source={ source }/>)
      case 'historic':
          return(<Historic source={ source }/>)
      case 'settings':
        return(<Settings source={ source }/>)
      case 'eproducts':
        return(<Eproducts source={ source }/>)
      
      default: return "Ninguna Subruta"
        
      
    }
    


  }

    return (
    
    <div>
        <NavigationAdmin/>
        
        <div id='dashboard' className='text-center'>
          <div className='container-fluid'>

          <NavigationSource 
            id={source}
            />

            <div className='section-title'>
              <h2>Source Name</h2>
              
            </div>
             
            
            <div className='row'>
              <div className='dashboard-items'>

              { modePath(path, source)}
              
              </div>
            </div>
          </div>
    </div>
     
    
    </div> 
    );
  }
  
  export default Source;
 