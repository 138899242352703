import React from "react";
import { useState, useEffect } from "react";
import { RulesActions } from "./rulesActions";
import { RulesGlobalCondition } from "./rulesGlobalConditions";
import channelService from "../../services/channels";

export const Rule = (props) => {

    const [ruleData, setRuleData ] = useState("");
    const [editTitle, setEditTitle] = useState(false);
    const [message, setMessage] = useState("");

    useEffect(() => {
        channelService.getRule(props.id, props.rule).then(
        (response) => {
            setRuleData(response.data.data);
            setMessage(false);
        },
        (error) => {
            setMessage("error al cargar los Campos: " + error);
            
        }
        );
    }, []);
   
    const updatetitle =  (title) => {
        
        let newObj = {...ruleData}; 
        newObj.name= title; 
        setRuleData(newObj)
        
    }
    const handleTitle = (e) => {
        
        const title = e.target.value;
        updatetitle(title);
        
      }

    const updateActions =  (updatedActions) => {
        
        let newObj = {...ruleData};
        newObj.actions= updatedActions; 
        setRuleData(newObj)
    }

    const updatedGlobalConditionsAny = (globalConditionsAny) =>{
        
        let newArr = {...ruleData};
        newArr.conditions.any = globalConditionsAny; 
        setRuleData(newArr)
    }

    const updatedGlobalConditionsAll = (globalConditionsAll) =>{
        
        let newArr = {...ruleData};
        newArr.conditions.all = globalConditionsAll; 
        setRuleData(newArr)
        
    }

    const addGlobalCondition = (globalConditional, conditional) => {
        
        let newObj = {...ruleData};
        let newGlobalCondition = '';

        switch(conditional){

            case 'any':
                newGlobalCondition = {any:[{field : "",operator : "",value : ""}]};
                break;
            case 'all':
                newGlobalCondition = {all:[{field : "",operator : "",value : ""}]};
                break;
        }
        switch(globalConditional){

            case 'any':
                newObj.conditions.any.push(newGlobalCondition);
                break;
            case 'all':
                newObj.conditions.all.push(newGlobalCondition);
                break;
        }
        setRuleData(newObj);
       
    }
    

    const updateRule = () =>{

        channelService.editRule(ruleData).then(
            (response) => {
                setMessage(false);
            },
            (error) => {
                setMessage("error al guardar la regla: " + error);
            }
        );

    }

   

    

    return (
        <div>
        { ruleData ?
        <div className="card">
            
           <div className="card-header d-flex align-items-center">
                <h5>{ruleData.name} ({props.rule})</h5>
                <button type="button" className="btn btn-primary btn-cardRule" onClick={() => (setEditTitle(true))} >Edit Name</button>
                
            </div>
            
            {editTitle ? 
            <div className="card-header">
                <input 
                    type="text" 
                    name="ruleName"
                    className="form-control" 
                    placeholder="Name of the Rule" 
                    value={ruleData.name}
                    onChange={handleTitle}
                    onBlur={() => (setEditTitle(false))}
                            
                />
            </div>
            : ''}
            
            <div className="card-body">
                {message && (
                <div className="form-group">
                <div className="alert alert-danger" role="alert">
                    {message}
                </div>
                </div>
                )}
                {ruleData.conditions.any ? 
                    <div className= "card mb-3">
                        <div className="card-header bg-primary text-white">
                        <h5>Cuando se cumpla alguna de estas Reglas</h5> 
                        </div>
                        <div className="card-body" >
                        {
                            ruleData.conditions.any ? 
                            <RulesGlobalCondition
                                idRule= {props.idRule}
                                globalConditions = {ruleData.conditions.any}
                                updatedGlobalConditions = {updatedGlobalConditionsAny}
                                key="ANY"
                                source={props.source}
                            />    

                            :''
                        }
                        <button 
                            type="button" 
                            className="btn btn-primary btn-cardRule"
                            onClick={() => addGlobalCondition('any','all')}
                        >
                        AND
                        </button>
                        <button 
                            type="button" 
                            className="btn btn-primary btn-cardRule"
                            onClick={() => addGlobalCondition('any','any')}
                        >
                        OR
                        </button>
                        </div>
                    </div>
                : ''}

                {ruleData.conditions.all ?
                    <div className= "card mb-3">
                        <div className="card-header bg-primary text-white">
                        <h5>Cuando se cumplan Todas estas Reglas</h5> 
                        </div>
                        <div className="card-body" >
                        {
                            ruleData.conditions.all ? 
                            <RulesGlobalCondition 
                            idRule= {props.idRule}
                            globalConditions = {ruleData.conditions.all}
                            updatedGlobalConditions = {updatedGlobalConditionsAll}
                            key="ALL"
                            source={props.source}
                            />    

                            :''
                        }
                        <button 
                            type="button" 
                            className="btn btn-primary btn-cardRule"
                            onClick={() => addGlobalCondition('all','all')}
                            >
                        AND
                        </button>
                        <button 
                            type="button" 
                            className="btn btn-primary btn-cardRule"
                            onClick={() => addGlobalCondition('all','any')}
                            >
                        OR
                        </button>
                        </div>
                    </div>
                : ''}
                <div className= "card mb-3">
                    <div className="card-header bg-primary text-white">
                    <h5>Entonces</h5>
                    </div>
                    <div className="card-body" >
                    {
                        ruleData.actions ? 
                        <RulesActions
                        channel = {props.id}
                        actions = { ruleData.actions }
                        updatedActions = {updateActions}
                        source={props.source}
                        />

                    : "Loading..."
                    }
                    </div>

                
                </div>
            
                <button type="button" className="btn btn-primary btn-cardRule" onClick= {updateRule}>
                Guardar
                </button>
            </div>
            
        </div>
        : "loading" }   
        </div>        
        
        
        
        
    );

  }
