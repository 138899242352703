import React, { useState, useEffect } from "react";
import sourceService from "../../services/source";

export const Historic = (props) => {

    const [importsList, setImportsList] = useState("");
    const [message, setMessage] = useState("");

    
    useEffect(() => {
        
       sourceService.getSourceImports(props.source).then(
            (response) => {
                
                setImportsList(response.data.data);
                setMessage(false);
            },
            (error) => {
                setMessage("error al cargar los Campos: " + error);
            }
            );

    }, []);

    
    return (
        
        <div className="container-fluid">
            <p>Histórico de las últimas importaciones del source:</p>
            
            { message && (
            <div className="form-group">
              <div className="alert alert-danger" role="alert">
                {message}
              </div>
            </div>
            )}
            
            
            <div className="table-responsive"> 
                <table className="table previewTable"> 
                    <thead>
                        <tr>
                            <th>ID Import</th>
                            <th>Fecha</th>
                            <th>Productos</th>
                            <th>Errors</th>
                            <th>Estado</th>
                            
                        </tr>
                    </thead>
                    <tbody>
                        {importsList ? importsList.map((imports) => 
                        <tr key={imports._id}> 
                            <td>{imports._id}</td>
                            <td>{imports.updatedAt}</td>
                            <td>{imports.products}</td>
                            <td>{imports.errorList.length}</td>
                            <td>{imports.status}</td>
                        </tr>
                        )
                    : <div className='spinner'></div>}
                    </tbody>
                </table> 
            </div>
            
            
        </div>
        
        
    );
  }
