import React from "react";


import { NavigationAdmin } from "../components/navigationAdmin";
import { Welcome } from "../components/home/welcome";
import { Help } from "../components/home/help";
import { Summary } from "../components/home/summary";
import { Footer } from "../components/footer";


function Dashboard() {

    return (
    
    <div>
        <NavigationAdmin/>
        <div id='dashboard' className='text-center'>
          <div className='container-fluid'>
            
            <Welcome/>
            <Summary />
            <Help />
            
            
            <Footer />  
          </div>
    </div>
     
    
    </div> 
    );
  }
  
  export default Dashboard;