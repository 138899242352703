import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Chart as ChartJS } from 'chart.js/auto'
import { Bar } from "react-chartjs-2";

import channelService from "../../services/channels";
import sourceService from "../../services/source";

export const Summary = (props) => {

  const [channelsData, setchannelsData] = useState("");
  const [sourcesData, setSourcesData] = useState("");
  const [message, setMessage] = useState("");
    
    useEffect(() => {
      channelService.dashboardChannels().then(
        (response) => {
          setchannelsData(response.data.data);
        },
        (error) => {
          setMessage("error al cargar los canales: " +error);
        }
      );

      sourceService.dashboardSources().then(
        (response) => {
          setSourcesData(response.data.data);
        },
        (error) => {
          setMessage("error al cargar los canales: " +error);
        }
      );


    }, []);
    
    //https://www.chartjs.org/docs/3.3.0/charts/bar.html
    const dataSources = {
  
      datasets: [
        {
          label: "Sources",
          data: sourcesData,
          fill: false,
          backgroundColor: 'rgba(113, 34, 209, 0.2)',
          borderColor: 'rgb(113, 34, 209)',
          borderWidth: 1
        }
      ]
    };
    
    const dataChannels = {
      
      datasets: [
        {
          label: "Channels",
          data: channelsData,
          fill: false,
          backgroundColor: 'rgba(50, 123, 232, 0.2)',
          borderColor: 'rgb(50, 123, 232)',
          borderWidth: 1
        }
      ]
    };

    

    return(

      <div className="container">
        <div className="row row-cols-1 row-cols-sm-2">
          <div className="col">
            <div className="card shadow-sm">
              <div className="card-body">
                <h5 className="card-title">Sources Status</h5>
                <p className="card-text">Productos Indexados en la plataforma</p>
                {sourcesData ? <Bar data={dataSources} /> : 'loading'}
                <div className="justify-content-between align-items-center">
                  <Link className="nav-link p-0" to={`/sources`} >
                  <button type="button" className="btn btn-sm btn-outline-secondary">View More</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card shadow-sm">
              <div className="card-body">
                <h5 className="card-title">Canales Status</h5>
                <p className="card-text">Productos optimizados en el Canal de difusión.</p>
                {channelsData ? <Bar data={dataChannels} /> : 'loading'}
                <div className="justify-content-between align-items-center">
                  <Link className="nav-link p-0" to={`/channels`} >
                  <button type="button" className="btn btn-sm btn-outline-secondary">View More</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
            
    );
    

}