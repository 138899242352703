import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import channelService from "../services/channels";

import { NavigationAdmin } from "../components/navigationAdmin";
import { NavigationChannel } from "../components/navigationChannel";

import { Categories } from "../components/categories/categories";
import { Fields } from "../components/fields/fields";
import { Rules } from "../components/rules/rulesField";
import { Rule } from "../components/rules/rule";
import { Preview } from "../components/preview/preview";
import { Exclusions } from "../components/exclusions/exclusions";
import { Exclusion } from "../components/exclusions/exclusion";
import { Settings } from "../components/settings/settings";
import { Historic } from "../components/reports/historic";



const Channel = () => {

  

  const { channel, path, field, rule, category } = useParams();
  const [channelInfo, setChannelInfo] = useState("");

  useEffect(() => {
    setChannelInfo(channelService.getChannelInfo(channel));
  }, []);

  
  const modePath = (path, channel, field, rule, category) => {

    switch(path)
    {
      case 'category':
        return(<Categories id={channel} platform ={channelInfo.platform}/>)
      case 'mapping':
        return(<Fields id={channel} source ={channelInfo.source} platform ={channelInfo.platform} />)
      case 'rules':
        if(rule) return <Rule id={channel} rule = {rule} source ={channelInfo.source}/>
        else return <Rules id={channel} field = {field} category = {category}/>
      case 'exclusions':
        if(field) return <Exclusion id={channel} exclusion = {field} source ={channelInfo.source}/>
        else return <Exclusions id={channel}/>
      case 'preview':
        return(<Preview id={channel}/>)
      case 'settings':
        return(<Settings id={channel}/>)  
      case 'reports':
        return(<Historic id={channel}/>)

      default: return(<Historic id={channel}/>)
        
      
    }
    


  }

    return (
    
    <div>
        <NavigationAdmin/>
        
        <div id='dashboard' className='text-center'>
          <div className='container-fluid'>

          <NavigationChannel 
            id={channel}
            />

            <div className='section-title'>
              <h2>Channel {channelInfo.name}</h2>
              
            </div>
             
            
            <div className='row'>
              <div className='dashboard-items'>

              { channelInfo ? modePath(path, channel, field, rule, category) : "loading"}
              
              </div>
            </div>
          </div>
    </div>
     
    
    </div> 
    );
  }
  
  export default Channel;
 