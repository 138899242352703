import React from "react"

export const FieldPlace = (props) => {

    
    return(

        props.repeat ? Array.from(Array(props.repeat), (e, i) => {
            return (
            
                <div className="card mb-3" key={i}>
                    <div className="card-header placeholder-glow">
                    
                        <span className="placeholder col-10 bg-primary"></span>
                     
                    </div>
                    <div className="card-body">
                        <h5 className="card-title placeholder-glow">
                        <span className="placeholder col-6"></span>
                        </h5>
                        <p className="card-text placeholder-glow">
                        <span className="placeholder col-3"></span>
                        </p>
                        <p className="card-text placeholder-glow">
                        <span className="placeholder col-3"></span>
                        </p>
                        <p className="card-text placeholder-glow">
                        <span className="placeholder col-10"></span>
                        </p>
                    </div>
                </div>
            
        )})
        
        : null
    );
    

}