import React, { useState, useEffect } from "react";
import channelService from "../../services/channels";
import { TableRowPlace } from "../placeholders/tablerow";
import { TableHeaderPlace } from "../placeholders/tableheader";

export const Preview = (props) => {

    const [fields, setFields] = useState("");
    const [rowfields, setRowFields] = useState("");
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [totalPages, setTotalPages] = useState("");
    const [message, setMessage] = useState("");

    
    useEffect(() => {
        channelService.getFields(props.id).then(
            (response) => {
                
                setFields(response.data.data);
                setMessage(false);
            },
            (error) => {
                setMessage("error al cargar los Campos: " + error);
            }
        );

        channelService.getOptimizedFeed(props.id, limit, page).then(
            (response) => {
                
                setRowFields(response.data.data.feed);
                setPage(response.data.data.page);
                setTotalPages(response.data.data.totalPages-1);
                setMessage(false);
            },
            (error) => {
                setMessage("error al cargar los Campos: " + error);
            }
        );

    }, []);

    const updateOptimizedFeed = (limit, page) => {
        channelService.getOptimizedFeed(props.id, limit, page).then(
            (response) => {
                
                setRowFields(response.data.data.feed);
                setPage(response.data.data.page);
                setTotalPages(response.data.data.totalPages-1);
                setMessage(false);
            },
            (error) => {
                setMessage("error al cargar los Campos: " + error);
            }
        );

    }
    const generateOptimizedFeed = () => {
        channelService.generateOptimizedFeed(props.id).then(
            (response) => {
                updateOptimizedFeed(limit, page);
                setMessage(false);
            },
            (error) => {
                setMessage("error al cargar los Campos: " + error);
            }
        );

    }

    const handleNextPage = () => {

        if(page < totalPages){
            const nextPage = parseInt(page)+1;
            updateOptimizedFeed(limit, nextPage);
        }
        else{
            //Todo
        }
    };
    const handlePrevPage = () => {

        if(page >= 1){
            const prevPage = parseInt(page)-1;
            updateOptimizedFeed(limit, prevPage);
        }
        else{
            //Todo
        }
    };
    const handleFieldValue= (field, product) => {
        //{ field.category.idMarket ? row.product[field.name+field.category.idMarket].value : row.product[field.name].value}
        let value = '';

        //Atributo de categoría
        if(field.category.idMarket){
            //Si existe la Propiedad cargamos su valor a value
            if(product.hasOwnProperty(field.name+field.category.idMarket)){
                value=product[field.name+field.category.idMarket].value;
            }
            //Si no existe la propiedad dejamos value en blanco
            else{
                value = '';
            }

        }
        //Atributo Común
        else{
            //Si existe la Propiedad cargamos su valor a value
            if(product.hasOwnProperty(field.name)){
                value=product[field.name].value;
            }
            //Si no existe la propiedad dejamos value en blanco
            else{
                value = '';
            }


        }
        return value;
        
    };

    
    return (
        
        <div className="container-fluid">
            <p>Así quedarán sus Productos al enviarse</p>

            <h6>Página {page} de {totalPages}</h6>
            <button 
                type="button" 
                className="btn btn-primary btn-cardRule"
                onClick={() => handlePrevPage()}
            > Prev Pagina</button>
            <button 
                type="button" 
                className="btn btn-primary btn-cardRule"
                onClick={() => handleNextPage()}
            > Next Pagina</button>
            <button 
                type="button" 
                className="btn btn-primary btn-cardRule"
                onClick={() => generateOptimizedFeed()}
            > Regenerate Feed</button>
            
            { message && (
            <div className="form-group">
              <div className="alert alert-danger" role="alert">
                {message}
              </div>
            </div>
            )}
            
            
            <div className="table-responsive"> 
                <table className="table previewTable"> 
                    <thead> 
                        <tr> 
                            {fields ? fields.map( (field) => 
                            <th>{field.name} ({field.category.nameMarket } )</th>
                            
                            ) : <TableHeaderPlace/>}
                        </tr> 
                    </thead> 
                    <tbody> 
                    {rowfields ? rowfields.map( (row) =>             
                        <tr> 
                            {
                            fields ? fields.map( (field) => 
                            <td>
                                <div className="previewCell"> 
                                { handleFieldValue (field, row.product)}
                                </div>
                            </td>
                            ) : <TableRowPlace/>}
                             
                        </tr>
                    ) : null}
                    </tbody> 
                </table> 
            </div>
            
            
        </div>
        
        
    );
}
