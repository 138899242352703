import React from "react";
import { Link } from "react-router-dom"
import { useState, useEffect } from "react";
import channelService from "../../services/channels";
import { RulePlace } from "../placeholders/rule";

export const Rules = (props) => {

    const [rulesData, setRulesData] = useState("");
    const [dragId, setDragId] = useState();
    const [message, setMessage] = useState("");

    useEffect(() => {
        
        channelService.getRulesField(props.id, props.field, props.category).then(
        (response) => {

            setRulesData(response.data.data);
            setMessage(false);
        },
        (error) => {
            setMessage("error al cargar los Campos: " + error);
        }
        );
    }, []);

    const handleAddRule = (field, category) => {
        channelService.postRule(props.id, field, category).then(
            (response) => {
                setRulesData([...rulesData, response.data.data]);
                setMessage(false);
            },
            (error) => {
                setMessage("No se pudo crear la regla: " + error);
                
            }
        );
    }

    const handleDragOver = (ev) => {
        ev.preventDefault()
      };

    const handleDrag = (ev) => {
        setDragId(ev.currentTarget.id);
      };
    
      const handleDrop = (ev) => {
        const dragBox = rulesData.find((rule) => rule._id === dragId);
        const dropBox = rulesData.find((rule) => rule._id === ev.currentTarget.id);
    
        const dragBoxOrder = dragBox.order;
        const dropBoxOrder = dropBox.order;
    
        //intercambia el orden del Drag and Drop
        const newBoxState = rulesData.map((rule) => {
          if (rule._id === dragId) {
            rule.order = dropBoxOrder;
          }
          
          if (rule._id === ev.currentTarget.id) {
            rule.order = dragBoxOrder;
          }
          return rule;
        });

        //Volver a ordenar y asignar nuevo orden
        let x = 1;
        const newRulesOrder = newBoxState
            .sort((a, b) => a.order - b.order)
            .map((rule) => {
                rule.order = x;
                x++;
                return rule;
            });
        
            
        //Endpoint para guardar
        setRulesData(newRulesOrder);
        channelService.editRulesOrder(newRulesOrder).then(
            (response) => {
                //setRulesData([...rulesData, response.data.data]);
                setMessage(false);
            },
            (error) => {
                setMessage("No se pudo guardar en nuevo orden: " + error);
                
            }
        );
      };

        //Eliminar Regla
        const handleDeleteRule = (rule) => {

            channelService.deleteRule(rule).then(
                (response) => {
                    //eliminamos del array de Exclusiones el id eliminado TEMPORAL lo ideal seria que volvieran los datos en la respuesta.
                    const resultDelete = rulesData.filter(exc => exc._id !== rule);
    
                    setRulesData(resultDelete);
                    setMessage(false);
                },
                (error) => {
                    setMessage("No se pudo eliminar la Regla: " + error);
                    
                }
            );
        }

    
    

    return (
        
        <div>
            
            <h4>Reglas del campo: {props.field} {props.category ? props.category : null}</h4>

            { message && (
            <div className="form-group">
              <div className="alert alert-danger" role="alert">
                {message}
              </div>
            </div>
            )}

            <div>

                
                <button 
                            type="button" 
                            className="btn btn-primary btn-cardRule"
                            onClick={() => handleAddRule(props.field, props.category)}
                >Añadir Regla</button>

                {rulesData ? rulesData
                    .sort((a, b) => a.order - b.order)
                    .map((rule) => (

                    

                    <div className="card mb-3" 
                        draggable={true}
                        key={rule._id}
                        id={rule._id}
                        onDragOver={handleDragOver}
                        onDragStart={handleDrag}
                        onDrop={handleDrop}
                    >
                    <button
                        type="button" 
                        className="btn-close"
                        onClick={e =>
                        window.confirm("Seguro que quieres Eliminar esta Regla") &&
                        handleDeleteRule(rule._id)
                        }
                    ></button>
                    <Link className="nav-link" to={`/channels/${props.id}/rules/${props.field}/${rule._id}`}>
                        <div className="card-header bg-transparent text-white d-flex">
                            
                            <h4>{rule.order}</h4>
                        </div>
                        <div className="card-body">
                        <h5 className="card-title">{rule.name}</h5>
                        <h6 className="card-subtitle mb-2 text-muted">{rule.updatedAt}</h6>
                        <p className="card-text"></p>
                        </div>
                    </Link>        
                    </div>
                    
                    
                
                )
                ) : <RulePlace repeat= {1}/>
                }
            </div>
            
            
        </div>
        
        
    );
  }
