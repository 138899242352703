import { Link } from "react-router-dom";
import AuthService from "../services/auth";

const handelLogout = () =>{
  AuthService.logout();
  window.location.reload();

}

export const NavigationAdmin = (props) => {
  return (
    <nav className="navbar navbar-expand-lg navbar-dark  bg-primary bg-gradient">
      <div className="container-fluid">
        <a className="navbar-brand" href="#">empooria</a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
            <Link className="nav-link" to="/sources">Sources</Link>
            </li>
            <li className="nav-item">
            <Link className="nav-link" to="/channels">Channels</Link>
            </li>
          </ul>
          
        </div>
        <div className="dropdown dropstart">
          <a href="#" className="d-block link-dark text-decoration-none dropdown-toggle" id="dropdownUser2" data-bs-toggle="dropdown" aria-expanded="false">
            <img src="img/person.svg" alt="mdo" width="32" height="32" className="rounded-circle"/>
          </a>
          <ul className="dropdown-menu text-small shadow" aria-labelledby="dropdownUser2">
            <li><a className="dropdown-item" href="#">Settings</a></li>
            <li><a className="dropdown-item" href="#">Profile</a></li>
            <li><a className="dropdown-item" onClick={handelLogout} href="#">Sign out</a></li>
          </ul>
        </div>  
      </div>
    </nav>

  )
}
