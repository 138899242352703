import axios from "axios";
import authHeader from "./auth-header";
import config from "../config";

const API_URL = config.hostname;

const getSources = () => {
    return axios.get(API_URL + "sources", { headers: authHeader() });
};

//Dashboard
const dashboardSources = () => {
  return axios.get(API_URL + "sources/dashboard", { headers: authHeader() });
};
//

const getSource = (source) => {
  return axios.get(API_URL + "sources/" + source, { headers: authHeader() });
};

const updateSource = (source, data) => {
  return axios.put(API_URL + "sources/" + source + "?action=import", data, { headers: authHeader() });
};

const getSourceFields = (source) => {
  return axios.get(API_URL + "imports/source/fields/" + source, { headers: authHeader() });
  };

const getSourceImports = (source) => {
  return axios.get(API_URL + "imports/source/all/" + source, { headers: authHeader() });
};
  
const getProductsPreview = (source, limit, page, eproduct = false) => {
    return axios.get(API_URL + "imports/source/products/" + source + "/?limit="+ limit + "&page=" + page + "&eproduct=" + eproduct, { headers: authHeader() });
};

const getFeedFieldValues = (source, field) => {
  return axios.get(API_URL + "rules/fieldvalues/" + source + "/" + field, { headers: authHeader() });
};

//Add/Edit Products
const updateProduct = (source, dataProduct) => {

  const data = {
    source,
    dataProduct
  }
  return axios.post(API_URL + "products/update", data, { headers: authHeader() });
};
const getProduct= (source, product) => {
  return axios.get(API_URL + "products/" + source + "/" + product, { headers: authHeader() });
};
const deleteProduct = (product) => {
  const data = {product};
  return axios.post(API_URL + "products/delete", data, { headers: authHeader() });
};


export default {
    getSource,
    dashboardSources,
    getSources,
    updateSource,
    getSourceFields,
    getSourceImports,
    getProductsPreview,
    getFeedFieldValues,
    updateProduct,
    getProduct,
    deleteProduct,
  };