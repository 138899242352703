import React from "react"

export const Welcome = (props) => {


    
    return(

        
      <section className="py-5 text-center container bg-home">
        <img className="d-block mx-auto mb-4" src="/e_logo_512.png" alt="" width="128" height="128"/>
        <div className="row py-lg-5">
        
          <div className="col-lg-6 col-md-8 mx-auto">
            <h1 className="fw-light">empooria</h1>
            <p className="lead text-muted">Conecta, adapta, difunde</p>
            
          </div>
        </div>
      </section>
            
    );
    

}