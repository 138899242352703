import { Link } from "react-router-dom"
import React, { useState, useEffect } from "react";
import { SearchField } from "./searchField";
import channelService from "../../services/channels";
import { FieldPlace } from "../placeholders/field";
import { FieldCatPlace } from "../placeholders/fieldcategory";

export const Fields = (props) => {

    const [fields, setFields] = useState("");
    const [feedFields, setFeedFields] = useState("");
    const [searchingField, setSearchingField] = useState(false);
    const [editingField, setEditingField] = useState(false);
    const [message, setMessage] = useState("");
    const [categories, setCategories] = useState([]);
    const [filteredCategories, setFilteredCategories] = useState("comun");

    useEffect(() => {
        channelService.getFieldsMap(props.id).then(
        (response) => {
            
            setCategories(categoriesList(response.data.data));
            setFields(response.data.data);
            
            
            setMessage(false);
        },
        (error) => {
            setMessage("error al cargar los Campos: " + error);
        }
        );

        
        //Necesario para validar que los campos introducidos son variables
        channelService.getSourceFields(props.source).then(
            (response) => {
                
                setFeedFields(response.data.data);
                setMessage(false);
            },
            (error) => {
                setMessage("error al cargar los Campos: " + error);
                
            }
            );
    }, []);

    
    const handleChange = (index) => (e) => {
        
        let newArr = [...fields];
        newArr[index].value = e.target.value
        setFields(newArr);
      }

      const handleDetectVariable = (index) => (e) =>{
        const preshKey = e.key;
        preshKey === "$" ? setSearchingField(index) : setSearchingField(false);
        
      }

      const handleMatchKeyword = (index) => (field) => {
        
        let newArr = [...fields];
        let actualValue = newArr[index].value; 
        newArr[index].value = actualValue+field;
        
        setSearchingField(false);
        setFields(newArr);
     }

     const namevalue = (names) => {
        const nameValues = JSON.parse(names)

        return nameValues.en.toString();    //Para que devuelva como string los Booleanos
     }

    const handleSave = () => {

        if(!searchingField){
            ///Endpoint guardar
            channelService.updateFieldsMap(props.id, fields).then(
                (response) => {
                    setMessage(false);
                },
                (error) => {
                    setMessage("error al guardar los Campos: " + error);
                }
            );

        }
        //ocultamos la edición del field
        setEditingField(false);
        
    }

    //--------------Nav Filtrado de Categorias---------
    const categoriesList = (fields) => {
        let arrCategories = [];
        if(fields){
            fields.map((field) => { 
                //Amazon
                if(props.platform === "amazon"){
                    if(field.category.idMarket && !arrCategories.includes(field.category.idMarket))
                    {
                        arrCategories.push(field.category.idMarket);
                    }
                }
                //Resto de Markets
                else{
                    if(field.category.nameMarket && !arrCategories.includes(field.category.nameMarket))
                    {
                        arrCategories.push(field.category.nameMarket);
                    }
                }
            })
            
            setCategories(arrCategories);

        }
        else{
            //Todo
        }
        return arrCategories;
    }
    const checkItsActive = (category) => {
        if(category === filteredCategories) return "list-group-item d-flex justify-content-between align-items-center active";
        else return "list-group-item d-flex justify-content-between align-items-center";

    }
    //devuelve el filtro de listado de categorias
    const showCategoriesList = () => {
        return (
            <ul className="list-group overflow-auto">
                <li className={checkItsActive("comun")} key={"comun"} onClick={() => setFilteredCategories("comun")}>
                    {"Atributos Comunes"}
                    <span className="badge bg-primary rounded-pill" key={"badgecomun"}>{countCategoryFields("comun")}</span>
                </li>
              {categories.map(category => (
                <li className={checkItsActive(category)} key={category} onClick={() => setFilteredCategories(category)}>
                    {category}
                    <span className="badge bg-primary rounded-pill" key={"badge"+category}>{countCategoryFields(category)}</span>
                </li>
              ))}
            </ul>
          );

    }
    //devuelve si se muestra el atributo según categoría filtrada
    const filterField = (filter, filterAmz) => {
        
        let filterCategory;
        props.platform === "amazon" ? filterCategory= filterAmz : filterCategory=filter;
                
        if(filteredCategories === filterCategory) return false;
        else if(filteredCategories === "comun" && filter === undefined) return false;
        else return true;

    }
    //devuelve el número de atributos de una categoría y el número de implementados
    const countCategoryFields = (category) => {
        let numberFields = 0;
        let numberValues = 0;
        if(fields){
            fields.map((field) => {
                //Verificamos nameMarket e idMarket por Amazon
                if(field.category.nameMarket === category || field.category.idMarket === category) {
                    numberFields++;
                    if(field.value) numberValues++;
                }
                else if(category === "comun" && field.category.nameMarket === undefined){
                    numberFields++;
                    if(field.value) numberValues++;
                }
            })
        }
        return numberValues + "/" + numberFields;

    }
    //Validación variables
    //Muestra el campo mapeado si es una variable, text, funcion(futuro)
    const showMappedFields = (values, key) => {

        if(values) {

            const separar = values.split(' ');
            //console.log(analizar);
            const analizar = separar.map((valor) => {
                //Variable
                if(valor.includes('$')){
                    //check si variable existe
                    if(checkItsSourceVariable(valor)){
                        return <span className="itsVariable" key={"span"+key+valor}>{valor} </span>
                    }
                    else{
                        return <span className="doesNotVariable" key={"span"+key+valor}> {valor}</span>
                    }
                    
                }
                //Operacion
                else if(valor.includes('CALC(') || valor.includes(')CALC')){
                    return <span className="itsCalc" key={"span"+key+valor}>{valor} </span>
                }
                //Texto
                else {
                    return <span className="doesNotVariable" key={"span"+key+valor}> {valor}</span>
                }
            })
            
            return analizar;
        }
        else{
            return ''
        }


    }
    //Comprueba que es una variable del source
    const checkItsSourceVariable = (valor) => {
        
        const fieldSource = valor.slice(1);
        if(feedFields){
            const newArr = [...feedFields];
            if(newArr.includes(fieldSource)) return true;
            else return false;
        }
        else{
            //
        }
        //return true;
        

    }
    //Activa la edición de un campo
    const handleDetectEditing = (index) => (e) =>{
        
        setEditingField(index);
        
      }


    return (
        
        <div className="container-fluid">
            <p>Enlace los campos de su feed de datos con la estructura del canal para definir cómo se mostrarán sus productos.</p>
            <div className="row">
                <div className="col-2">
                    { categories  ? showCategoriesList() : <FieldCatPlace repeat={8}/>}
                </div>
                <div className="col-10">

                    { message && (
                    <div className="form-group">
                    <div className="alert alert-danger" role="alert">
                        {message}
                    </div>
                    </div>
                    )}
                    
                    { fields  ? fields.map((field,key) => (
                        <div className="card mb-3" key={"panel"+key} hidden={filterField(field.category.nameMarket, field.category.idMarket)}> 
                            <div className="card-header bg-primary text-white">
                                {field.originalname ? field.originalname : field.name}
                            </div>
                            <div className="card-body" >
                                <h5 className="card-title">{field.description}</h5>
                                
                                { field.category.nameMarket ? 
                                    <p className="card-text">({field.category.nameMarket}) {field.category.idMarket}</p>
                                    : null
                                }
                                { field.isvariation ? 
                                <p className="card-text">**Atributo Variación**</p>
                                : null
                                }
                                { field.mandatory ? 
                                    <p className="card-text">**Atributo Obligatorio**</p>
                                : null
                                }
                                { field.inputtype ? 
                                <p className="card-text">{field.inputtype} {" ex:" + field.exemple}</p>
                                : null
                                }
                                <div className="row">
                                    <div className="col-10">
                                        
                                        { 
                                        //Si es una lista de valores o check crear desplegable
                                        field.type === 'list_box' || field.type === 'check_box' ? 
                                        <select  className="form-select" value={field.value} onChange={handleChange(key)} onBlur={handleSave}>

                                            <option value={''} key={''}>{'-select value-'}</option>
                                            { field.values ? field.values.map((o)=>
                                                <option value={o.id} key={o.id}>{namevalue(o.names)}</option>
                                            ):
                                            'loading' 
                                            }
                                        </select>
                                                                
                                        : //Si no, mostrar input si la edición esta activa o div si la edición no esta activa
                                        
                                        editingField === key ?  
                                            <input 
                                                
                                                type="text" 
                                                className="form-control input-lg" 
                                                placeholder="Introduzca $ para los valores"
                                                value = {field.value}
                                                onChange={handleChange(key)}
                                                onKeyPress={handleDetectVariable(key)}
                                                onBlur={handleSave}
                                            /> :
                                            <div className="list-group-item d-flex align-items-center" key={"show"+key} onClick={handleDetectEditing(key)} >
                                                {showMappedFields(field.value, key)}
                                            </div>
                                        }
                                    </div>
                                
                                
                                    <div className="col-2">
                                        <Link to={`/channels/${props.id}/rules/${field.name}/category/${field.category.idMarket ? field.category.idMarket : "0"}`}>
                                            <button className="btn btn-primary d-grid gap-2" type="button">Reglas</button>
                                        </Link>
                                    </div>
                                </div>
                                
                            </div>
                        
                        { searchingField === key ? 
                        <div className="row row-searching">    
                            <SearchField
                                key = {'search'+key}
                                matchKeyword = {handleMatchKeyword(key)}
                                source = {props.source}
                            />
                        </div>   
                            : ''

                        }
                        
                    </div>    
                    )
                    
                    ) : <FieldPlace repeat = {5}/>
                    }
                </div>
            </div>
        </div>
        
        
    );
  }
