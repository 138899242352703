import React, { useState, useEffect } from "react";
import sourceService from "../../services/source";

export const ConversionField = (props) => {

    const [validValues, setValidValues] = useState(props.validvalues);
    const [setValues, setSetValues] = useState(props.setValues);
    const [feedFieldValues, setFeedFieldValues] = useState("");
    const [feedFields, setFeedFields] = useState("");
    const [message, setMessage] = useState("");
    const [activeValue, setActiveValue] = useState("");
    const [conversionVar, setConversionVar] = useState(false);
    const [keyword, setKeyword] = useState("");
    
    useEffect(() => {
        //Cargamos los campos de nuestro feed de origen
        sourceService.getSourceFields(props.source).then(
            (response) => {
                setFeedFields(response.data.data);
                setMessage(false);
            },
            (error) => {
                setMessage("error al cargar los Campos: " + error);
            }
            );

        //Cargamos todos los valores posibles de un campo de nuestro feed de origen
        if(props.source && props.conversionField){
            sourceService.getFeedFieldValues(props.source, props.conversionField).then(
                (response) => {
                    setFeedFieldValues(response.data.data);
                    //Guardamos la conversión actual + actualizamos por si hay algún valor nuevo pendiente de asignar
                    setSetValues(generateSetValues(response.data.data));
                    setMessage(false);
                },
                (error) => {
                    setMessage("error al cargar los Campos: " + error);
                }
            );
        }
        

      
    }, []);

    const checkIfValueExist = (data, value) => {
        return data.find(field => field.feedvalue === value);
    };

    //Nos genera un Array de la conversión actual del campo, checkea si existe algún nuevo valor pendiente de asignar
    const generateSetValues = (feedFieldValues) => {

        let newArr = [...setValues];
        feedFieldValues.map((element) => {
            const valueExist = checkIfValueExist(newArr, element.feedvalue);
            if(!valueExist){
                newArr.push( {
                    feedvalue: element.feedvalue,
                    channelvalue: "",
                    channelname: ""
                });
            }
        });

        return newArr;
    };

    //Parse del string de idiomas del campo, trabajamos en english
    const namevalue = (names) => {
        const nameValues = JSON.parse(names)
        return nameValues.en;
     }

    //Guardamos el campo activo para su conversión, mediante evento onClick
    const handleSelecionar = (field,k) => {
        setKeyword("");
        setActiveValue(k);
        setConversionVar(true);
    }
    //Devuelve a Props los valores emparejados
    const handleEmparejar = (field) => {
        let newArr = [...setValues];
        newArr[activeValue].channelvalue = field.id;
        newArr[activeValue].channelname = namevalue(field.names);

        setSetValues(newArr);
        setConversionVar(false);
        props.updateValues(newArr);
        
    }
    //Devuelve a Props el valor actualizado del campo a convertir
    const handleChangeField = (e) => {
        props.updateConversionField(e.target.value);
    }

    const handleChange = (e) => {
        setKeyword(e.target.value); 
     }




    return(
        <div className="conversion-container">
            
            <div className="input-group-prepend">
            <h3>Campo del Feed de origen seleccionado a convertir: {props.conversionField}</h3>
            </div>
                    
            <select name= "field" className="form-control" value={props.conversionField} onChange={handleChangeField}>
                { feedFields ? feedFields.map((f)=>
                    <option value={f} key={f}>{f}</option>
                ):
                'loading' 
                }
            </select>
            <div> 
            
                <div className="row">
                    
                    <div className="col-6 conversion-box">
                        <div className="card">
                        <div className="card-header">
                        <h3>Valores Feed Origen</h3>
                        </div>
                        <div className="card-body">
                        { setValues ? setValues.map((element, key) => 
                        
                            <div
                            className = "conversion-field"
                            key={"valuefeed"+key}
                            onClick={()=> handleSelecionar(element,key)}
                            >
                                { element.feedvalue + "("+element.channelname+")" }
                            </div>

                            ) : "loading"
                        }
                        </div>
                        </div>
                    </div>

                    { conversionVar ? 
                    <div className="col-6 conversion-box">
                        <div className="card">
                        <div className="card-header">
                        <h3>Valores admitidos para el campo {props.field}</h3>
                        </div>
                        <div className="card-body">
                        <input  
                            type="text" 
                            name="valueSearch" 
                            className="form-control" 
                            placeholder="Search Value" 
                            value={keyword}
                            onChange={handleChange}
                        />
                        { props.validvalues ? props.validvalues.filter((el) => {
                                return el.names.toLowerCase().indexOf(keyword.toLowerCase()) > -1;
                            })
                        .map((validvalue,key) => 
                        
                            <div
                            className = "conversion-field"
                            key={"valuechannel"+key}
                            onClick={()=> handleEmparejar(validvalue)}
                            >
                                { namevalue(validvalue.names) }
                            </div>

                            ) : "loading"
                        }
                        </div>
                        </div>
                    
                    </div>
                    : ""}
                </div>
                    
                    
                    
                    
            </div>
                     
            
        </div>

    )}