import React from "react"

export const RulePlace = (props) => {

    
    return(

        props.repeat ? Array.from(Array(props.repeat), (e, i) => {
            return (
            
                <div className="card mb-3" key={i}>
                    <div className="card-header d-flex">
                    <h4 className="card-header placeholder-glow">
                        <span className="placeholder col-1 bg-primary"></span>
                        </h4>
                    </div>
                    <div className="card-body">
                        <h5 className="card-title placeholder-glow">
                        <span className="placeholder col-2 bg-primary"></span>
                        </h5>
                        <p className="card-text placeholder-glow">
                        <span className="placeholder col-3"></span>
                        
                        
                        </p>
                    </div>
                </div>
            
        )})
        
        : null
    );
    

}