import React, { useState, useEffect } from "react";
import channelService from "../../services/channels";

export const SearchType = (props) => {
    
    const [channelTypes, setChannelTypes] = useState("");
    const [message, setMessage] = useState('');
    

    useEffect(() => {
        
        channelService.postSearchType(props.channel).then(
        (response) => {
            setChannelTypes(response.data.data);
            setMessage(false);
        },
        (error) => {
            setMessage("error al cargar las categorías: " + error);
            
        }
        );
    }, []);


    const handleType = (category) => {
        
       props.matchType(category);
        
    }

   

    return(
        <div className="col searchingBox">
            <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon1">Searching...</span>
            </div>
            
            <ul className="list-group">
                { channelTypes ? channelTypes.map((f)=>
                            <li 
                            key={f._id}
                            className="list-group-item searching-li"
                            onClick={() => handleType(f.category)}>{f.category}
                            </li>
                            )
                            :
                            'loading' }
                
            </ul>
        </div>

    )}