import './App.css';
import React from "react";
import { Switch, Route } from "react-router-dom";
import Login from "./pages/login";
import AuthService from "./services/auth";

import Dashboard from './pages/dashboard';
import Sources from './pages/sources';
import Source from './pages/source';
import Channels from './pages/channels';
import Channel from './pages/channel';


function App() {

  const currentUser = AuthService.getCurrentUser();

  if(!currentUser) {
      return <Login />
  }
  
  
  
  return (
    <div>
    
    
    <Switch>
            <Route exact path="/" component={() => <Dashboard  />} />
            <Route exact path="/sources" component={() => <Sources  />} />
            <Route exact path="/sources/:source" component={() => <Source  />} />
            <Route exact path="/sources/:source/:path" component={() => <Source  />} />
            <Route exact path="/channels" component={() => <Channels  />} />
            <Route exact path="/channels/:channel" component={() => <Channel />}  />
            <Route exact path="/channels/:channel/:path" component={() => <Channel />}  />
            <Route exact path="/channels/:channel/:path/:field" component={() => <Channel />}  />
            <Route exact path="/channels/:channel/:path/:field/category/:category" component={() => <Channel />}  />
            <Route exact path="/channels/:channel/:path/:field/:rule" component={() => <Channel />}  />
                        
    </Switch>
    
    
    
  </div>
  );
}

export default App;